var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 md-layout-item md-medium-size-50 md-size-25 md-xsmall-size-100"},[_c('div',{staticClass:"projects__item"},[_c('router-link',{staticClass:"md-hidden-link",attrs:{"to":{
        name: 'Project',
        params: { id: _vm.item.id, name: _vm.item.name }
      }}}),_c('drop-down',{staticClass:"md-z-index-3 projects__toggle",attrs:{"direction":"down"}},[_c('md-button',{staticClass:"md-just-icon md-outline",attrs:{"slot":"title","data-toggle":"dropdown"},slot:"title"},[_c('md-icon',[_vm._v("more_horiz")])],1),_c('ul',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('li',[_c('router-link',{attrs:{"to":{
              name: 'Project',
              params: { id: _vm.item.id, name: _vm.item.name }
            }}},[_c('md-icon',[_vm._v("dashboard")]),_vm._v(" Manage Project ")],1)],1),_c('li',[_c('router-link',{attrs:{"to":{
              name: 'QueryNew',
              params: { id: _vm.item.id, name: _vm.item.name }
            }}},[_c('md-icon',[_vm._v("add_circle_outline")]),_vm._v(" Add New Query ")],1)],1),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('md-icon',[_vm._v("create")]),_vm._v(" Edit Project Details ")],1)]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('md-icon',[_vm._v("explore")]),_vm._v(" Explore Articles ")],1)]),(false)?_c('li',[_c('a',{attrs:{"href":"#"}},[_c('md-icon',[_vm._v("delete_outline")]),_vm._v(" Delete Project ")],1)]):_vm._e()])],1),_c('div',{staticClass:"hyphenate projects__title"},[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"hyphenate projects__desc"},[_vm._v(_vm._s(_vm.item.description))]),(false)?_c('div',{staticClass:"projects__bottom"},[_c('div',{staticClass:"projects__info"},[_c('md-icon',{staticClass:"color-parent md-outline"},[_vm._v("assignment")]),_c('span',[_vm._v("0 Articles")])],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }