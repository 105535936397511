<template>
  <div class="wrapper">
    <notifications></notifications>
    <div class="main-panel main-panel--full">
      <Header />
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Common/Header";
export default {
  name: "Default",
  components: { Header }
};
</script>
