import axios from "axios";

export default {
  state: {
    token: localStorage.getItem("access_token") || ""
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    LOGOUT(state) {
      state.token = "";
    }
  },
  actions: {
    FETCH_CODE(_, params) {
      return axios.post(`resend-act-code`, params);
    },
    LOGIN({ commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`get-access-code`, params)
          .then(res => {
            const token = res.data.access_token;
            if (token) {
              localStorage.setItem("access_token", token);
              axios.defaults.headers.common["X-GRI-ACCESS-TOKEN"] = token;
              commit("SET_TOKEN", token);
              resolve(res);
            } else {
              dispatch("LOGOUT");
              reject(res);
            }
          })
          .catch(err => {
            dispatch("LOGOUT");
            reject(err);
          });
      });
    },
    LOGOUT({ commit }) {
      return new Promise(resolve => {
        commit("LOGOUT");
        localStorage.removeItem("access_token");
        delete axios.defaults.headers.common["X-GRI-ACCESS-TOKEN"];
        resolve();
      });
    }
  },
  getters: {
    GET_TOKEN: s => s.token,
    IS_LOGGED_IN: s => !!s.token
  }
};
