export const isString = str => {
  return typeof str === "string";
};

export const anyToString = str => {
  return isString(str) ? str.trim() : "";
};

export const sliceText = (str = "", size = 999, ellipsis = false) => {
  const more = "...";
  let result = anyToString(str);
  result = result.slice(0, size).trim();
  if (ellipsis && result.length > size - more.length) {
    result += more;
  }
  return result;
};
