<template>
  <md-progress-spinner
    :class="{ 'spinner-lg': false }"
    md-mode="indeterminate"
    :md-diameter="size * 9"
    :md-stroke="size"
  ></md-progress-spinner>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    size: {
      type: Number,
      default: 3
    }
  }
};
</script>
