<template>
  <md-card class="position-static">
    <md-card-header
      class="align-items-start d-flex flex-wrap justify-content-between"
    >
      <div class="h4 mt-0 text-weight-400 title">{{ TITLE }}</div>
      <md-button
        v-if="firstReport"
        class="md-outline md-secondary ml-auto"
        :href="firstReport"
        target="_blank"
        rel="noopener noreferrer"
      >
        <md-icon class="md-outline">open_in_new</md-icon>
        {{ DASHBOARD }}
      </md-button>
    </md-card-header>
    <md-card-content>
      <ValidationObserver v-if="detailsEdit" ref="form">
        <form class="" @submit.prevent="update">
          <md-table>
            <md-table-row>
              <md-table-cell>{{ SCHEDULE_LABEL }}</md-table-cell>
              <md-table-cell>
                <md-switch v-model="scheduleToggle" class="mb-0 mt-0">
                  Daily
                </md-switch>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <ValidationProvider
            v-slot="{ passed, failed }"
            mode="eager"
            class="d-flex mb-4 pb-2"
            rules="numeric"
          >
            <md-field
              class="mb-0 mt-0"
              :class="{ 'md-error': failed, 'md-valid': passed }"
            >
              <label>{{ MAX_RES_QUERY }}</label>
              <md-input
                v-model="maxResQuery"
                type="text"
                placeholder="Unlimited"
              ></md-input>
              <span class="md-helper-text" :class="{ hide: failed }">
                {{ MAX_RES_QUERY_MSG }}
              </span>
              <span
                class="md-helper-error md-helper-text"
                :class="{ show: failed }"
              >
                {{ MAX_RES_QUERY_ERROR }}
              </span>
            </md-field>
          </ValidationProvider>
        </form>
      </ValidationObserver>
      <md-table v-model="analysisDetails" :class="{ 'opacity-3': detailsEdit }">
        <md-table-row
          v-for="item in analysisDetails.slice(detailsEdit ? 2 : 0)"
          :key="item.name"
        >
          <md-table-cell>{{ item.name }}</md-table-cell>
          <md-table-cell>{{ item.desc }}</md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions>
      <template v-if="detailsEdit">
        <md-button
          key="detailsEdit_close"
          class="md-danger md-just-icon md-round mr-4"
          :disabled="pendingUpdate"
          @click="close"
        >
          <md-icon class="md-outline">close</md-icon>
        </md-button>
        <md-button
          key="detailsEdit_check"
          class="md-just-icon md-primary md-round mr-4"
          :disabled="pendingUpdate"
          @click="update"
        >
          <ButtonLoader :is-load="pendingUpdate" icon="check" icon-outline />
        </md-button>
      </template>
      <template v-else>
        <md-button
          class="md-outline md-secondary"
          :disabled="pendingAnalyze"
          @click="showModal"
        >
          <md-icon class="md-outline">insights</md-icon>
          analyze now
        </md-button>
        <md-button
          class="md-outline md-secondary"
          :disabled="pendingAnalyze"
          @click="edit"
        >
          <md-icon class="md-outline">edit</md-icon>
          Edit
        </md-button>
      </template>

      <md-button
        class="md-just-icon md-outline md-secondary"
        @click="detailsShow = !detailsShow"
      >
        <md-icon class="md-outline md-size-1-5x">
          <template v-if="detailsShow">expand_less</template>
          <template v-else>expand_more</template>
        </md-icon>
      </md-button>
    </md-card-actions>

    <modal v-if="isShowModal" class="modal-footer-hide" @close="hideModal">
      <template slot="header">
        <h4 class="modal-title text-center text-weight-400">
          Run the analysis on the project <br />
          {{ projectTitle }}?
        </h4>
      </template>
      <template slot="body">
        <div class="md-layout">
          <div class="md-layout-item md-size-70 md-xsmall-size-100 mx-auto p-0">
            <div
              class="align-items-center d-flex justify-content-around mt-4 mx-auto text-center"
            >
              <md-button
                type="submit"
                class="md-primary"
                :disabled="pendingAnalyze"
                @click="analyzeNow"
              >
                <ButtonLoader text="Yes" :is-load="pendingAnalyze" />
              </md-button>
              <md-button
                type="submit"
                class="md-outline"
                :disabled="pendingAnalyze"
                @click="hideModal"
              >
                no
              </md-button>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </md-card>
</template>

<script>
import ButtonLoader from "@/components/Common/Button-loader";
import { anyToString, sliceText } from "@/utils/string";
import { formatDate } from "@/utils/formatDate";
import { required, length, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import errorHandler from "@/utils/errorHandler";
import { PROJECT, PROJECT_ANALYSIS } from "@/utils/constants";
import { Modal } from "@/components";

extend("required", required);
extend("length", length);
extend("numeric", numeric);

export default {
  name: "AnalysisDetails",
  components: { ButtonLoader, Modal },
  data() {
    return {
      detailsShow: false,
      detailsEdit: false,
      scheduleToggle: false,
      maxResQuery: this.maxResultsPerQueryValid,
      pendingUpdate: false,
      pendingAnalyze: false,

      isShowModal: false
    };
  },
  created() {
    this.TITLE = PROJECT_ANALYSIS.TITLE;
    this.DASHBOARD = PROJECT_ANALYSIS.DASHBOARD;

    this.SCHEDULE_LABEL = PROJECT_ANALYSIS.FORM.SCHEDULE_LABEL;
    this.MAX_RES_QUERY_LABEL = PROJECT_ANALYSIS.FORM.MAX_RES_QUERY_LABEL;
    this.MAX_RES_QUERY_MSG = PROJECT_ANALYSIS.FORM.MAX_RES_QUERY_MSG;
    this.MAX_RES_QUERY_ERROR = PROJECT_ANALYSIS.FORM.MAX_RES_QUERY_ERROR;
  },
  mounted() {
    this.setDefaultData();
  },
  computed: {
    projectTitle() {
      return sliceText(this.info?.name, PROJECT.MAX_NAME_LENGTH);
    },
    reports() {
      return this.$store.getters["GET_REPORT"];
    },
    firstReport() {
      return this.reports ? this.reports[0]?.link : undefined;
    },
    info() {
      return this.$store.getters["GET_PROJECT"];
    },
    analysisDetails() {
      const arr = [
        {
          name: PROJECT_ANALYSIS.SCHEDULE,
          desc: this.scheduleFormat // "Daily (7:00 AM EDT)"
        },
        {
          name: PROJECT_ANALYSIS.MAX_RES_QUERY,
          desc: this.maxResultsPerQueryVal
        },
        {
          name: PROJECT_ANALYSIS.LAST_ANALYSIS,
          desc: formatDate(this.info?.last_analyzed_at)
        },
        {
          name: PROJECT_ANALYSIS.STATUS,
          desc: anyToString(this.info?.last_analysis_status) // In Progress
        }
      ];
      return this.detailsShow ? arr : arr.slice(0, 2);
    },
    maxResultsPerQueryVal() {
      const val = this.info?.schedule_max_results_per_query;
      if (val === -1) return "Unlimited";
      if (val === 0) return 0;
      return val || "";
    },
    maxResultsPerQueryValid() {
      return this.maxResultsPerQueryVal === "Unlimited"
        ? ""
        : this.maxResultsPerQueryVal;
    },
    scheduleFormat() {
      return `${this.scheduleValid} ${formatDate(this.info?.scheduled_at)}`;
    },
    scheduleValid() {
      return this.scheduleParse(this.info?.schedule_frequency);
    },
    scheduleBoolean() {
      return this.scheduleValid === "daily";
    }
  },
  watch: {
    info() {
      this.setDefaultData();
    }
  },
  methods: {
    setDefaultData() {
      this.scheduleToggle = this.scheduleBoolean;
      this.maxResQuery = this.maxResultsPerQueryValid;
    },
    scheduleParse(str) {
      const newStr = anyToString(str);
      return newStr === "daily" ? newStr : "";
    },
    edit() {
      this.detailsShow = true;
      this.detailsEdit = true;
    },
    close() {
      this.setDefaultData();

      this.detailsShow = false;
      this.detailsEdit = false;
    },
    update() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.pendingUpdate = true;
          const maxResQuery = this.maxResQuery;

          this.$store
            .dispatch("UPDATE_PROJECT", {
              id: this.$route.params.id,
              param: {
                name: this.info?.name,
                description: this.info?.description,
                schedule_frequency: this.scheduleToggle ? "daily" : "none",
                schedule_max_results_per_query:
                  maxResQuery && maxResQuery.length >= 0 ? maxResQuery : -1
              }
            })
            .then(() => {
              this.$notifySuccess(PROJECT_ANALYSIS.SUCCESS_SAVE);
              this.close();
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingUpdate = false;
            });
        } else {
          this.$refs.form.validate();
        }
      });
    },
    analyzeNow() {
      this.pendingAnalyze = true;
      this.$http
        .post(`/projects/${this.$route.params.id}/fetch-analyze`, {
          id: this.$route.params.id
        })
        .then(() => {
          this.$notifySuccess(
            "Project analysis is started. You will receive an email after the analysis has been complete."
          );
        })
        .finally(() => {
          this.hideModal();
          this.pendingAnalyze = false;
        });
    },

    showModal() {
      this.isShowModal = true;
    },
    hideModal() {
      this.isShowModal = false;
    }
  }
};
</script>
