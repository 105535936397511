<template>
  <ul class="pagination" :class="{ disabled: disabled }">
    <li class="page-item prev-page">
      <md-button
        class="md-secondary md-simple"
        aria-label="Previous"
        :disabled="currentPage === 1"
        @click="currentPage -= 1"
      >
        Previous
      </md-button>
    </li>
    <li class="active page-item">
      <span class="page-link">{{ currentPage }}</span>
    </li>
    <li class="next-page page-item page-pre">
      <md-button
        class="md-secondary md-simple"
        aria-label="Next"
        :disabled="isLast"
        @click="currentPage += 1"
      >
        Next
      </md-button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    value: {
      type: Number,
      default: 1
    },
    isLast: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
