import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import projects from "@/store/modules/projects";
import news from "@/store/modules/news";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    projects,
    news
  }
});
