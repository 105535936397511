import axios from "axios";

const defaultForm = {
  name: "",
  type: "350638513bbf368b0",
  termQuery: "",
  site: "",
  scope: ""
};

export default {
  state: {
    list: [],
    pendingProject: false,
    project: undefined,
    reportList: undefined,
    queryList: undefined,
    queryForm: { ...defaultForm }
  },
  mutations: {
    SET_PROJECTS(state, list) {
      state.list = list;
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
    RESET_PROJECT(state) {
      state.project = undefined;
    },
    SET_QUERY_LIST(state, queryList) {
      state.queryList = queryList;
    },
    RESET_QUERY_LIST(state) {
      state.queryList = undefined;
    },
    SET_REPORT(state, report) {
      state.reportList = report;
    },
    UPDATE_PROJECT(state, param) {
      state.project = {
        ...state.project,
        ...param
      };
    },
    SET_QUERY_FORM(state, param) {
      state.queryForm = {
        ...state.queryForm,
        ...param
      };
    },
    RESET_QUERY_FORM(state) {
      state.queryForm = { ...defaultForm };
    },
    PENDING_PROJECT_START(state) {
      state.pendingProject = true;
    },
    PENDING_PROJECT_END(state) {
      state.pendingProject = false;
    }
  },
  actions: {
    FETCH_PROJECTS({ commit }) {
      return axios.get(`projects`).then(res => {
        commit("SET_PROJECTS", res.data.projects);
      });
    },
    CREATE_PROJECTS(_, params) {
      return axios.post(`project`, params);
    },
    FETCH_PROJECT({ commit }, id) {
      commit("PENDING_PROJECT_START");
      return axios
        .get(`projects/${id}`)
        .then(res => {
          commit("SET_PROJECT", res.data);
        })
        .finally(() => {
          commit("PENDING_PROJECT_END");
        });
    },
    FETCH_QUERY_LIST({ commit }, id) {
      return axios.get(`projects/${id}/queries`).then(res => {
        commit("SET_QUERY_LIST", res.data);
      });
    },
    CREATE_QUERY(_, { id, params }) {
      return axios.post(`projects/${id}/query`, params);
    },
    DELETE_QUERY(_, { projectId, queryID }) {
      return axios.delete(`projects/${projectId}/queries/${queryID}`);
    },
    UPDATE_QUERY(_, { projectId, queryID, params }) {
      return axios.put(`projects/${projectId}/queries/${queryID}`, params);
    },
    FETCH_REPORTS({ commit }, id) {
      return axios.get(`projects/${id}/reports`).then(res => {
        commit("SET_REPORT", res.data.items);
      });
    },
    UPDATE_PROJECT({ commit }, { id, param }) {
      return axios.put(`projects/${id}`, param).then(() => {
        commit("UPDATE_PROJECT", param);
      });
    }
  },
  getters: {
    GET_LIST_PROJECTS: s => s.list,
    GET_PROJECT: s => s.project,
    GET_QUERY_LIST: s => s.queryList,
    GET_QUERY_FORM: s => s.queryForm,
    GET_REPORT: s => s.reportList,
    STATUS_PROJECT: s => s.pendingProject
  }
};
