<template>
  <div class="main">
    <Preloader v-if="!countProjects" text="Loading projects..." />

    <template v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <Breadcrumbs />
        </div>
      </div>

      <template v-if="countProjects < MAX_COUNT">
        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-90 md-size-70 md-small-size-100 mx-auto"
          >
            <div class="h2">{{ TITLE }}</div>
            <p class="text-weight-500">
              {{ countProjects }}/{{ MAX_COUNT }}
              {{ DESC }}
            </p>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-70 md-size-50 md-small-size-90 md-xsmall-size-100 mx-auto"
          >
            <ValidationObserver ref="form">
              <form class="" @submit.prevent="submit">
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100">
                    <ValidationProvider
                      v-slot="{ passed, failed }"
                      mode="eager"
                      class="login__label"
                      rules="required"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed },
                          { 'md-large-input': true }
                        ]"
                      >
                        <label>{{ NAME_LABEL }}</label>
                        <md-input
                          v-model="name"
                          type="text"
                          :maxlength="MAX_NAME_LENGTH"
                          placeholder=""
                        >
                        </md-input>
                        <span
                          class="md-helper-error md-helper-text"
                          :class="{ show: failed }"
                        >
                          {{ NAME_ERROR }}
                        </span>
                        <slide-y-down-transition>
                          <md-icon v-show="failed" class="error">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon v-show="passed" class="success">
                            done
                          </md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ passed, failed }"
                      mode="eager"
                      rules="required"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed }
                        ]"
                      >
                        <label>
                          {{ DESC_LABEL }}
                        </label>
                        <md-textarea
                          v-model="desc"
                          class="md-textarea-large"
                          :maxlength="MAX_DESC_LENGTH"
                          md-autogrow
                        ></md-textarea>
                        <span
                          class="md-helper-error md-helper-text"
                          :class="{ show: failed }"
                        >
                          {{ DESC_ERROR }}
                        </span>
                        <slide-y-down-transition>
                          <md-icon v-show="failed" class="error">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon v-show="passed" class="success">
                            done
                          </md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="login__actions md-layout">
                  <div class="md-layout-item md-size-100">
                    <md-button
                      type="submit"
                      class="md-block md-lg md-primary"
                      :disabled="pendingForm"
                    >
                      <ButtonLoader
                        :text="BUTTON_SUBMIT"
                        :is-load="pendingForm"
                      />
                    </md-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-90 md-size-70 md-small-size-100 mx-auto text-center"
          >
            <div class="h2">
              {{ LIMIT }}
              <span class="text-primary">
                {{ countProjects }} of {{ MAX_COUNT }}
              </span>
            </div>
            <md-button :to="{ name: 'Projects' }" class="md-lg md-primary mt-5">
              {{ BACK }}
            </md-button>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ButtonLoader from "@/components/Common/Button-loader";
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, length } from "vee-validate/dist/rules";
import Preloader from "@/components/Common/Preloader";
import { PROJECT, PROJECTS } from "@/utils/constants";
import errorHandler from "@/utils/errorHandler";

extend("required", required);
extend("length", length);

export default {
  name: "ProjectNew",
  components: { Preloader, ButtonLoader, SlideYDownTransition },
  data() {
    return {
      name: "",
      desc: "",
      pendingForm: false
    };
  },
  created() {
    this.TITLE = PROJECT.CREATE_PROJECT.TITLE;
    this.DESC = PROJECT.CREATE_PROJECT.DESC;
    this.LIMIT = PROJECT.CREATE_PROJECT.LIMIT;
    this.BACK = PROJECT.CREATE_PROJECT.BACK;

    this.MAX_COUNT = PROJECTS.MAX_COUNT;
    this.MAX_NAME_LENGTH = PROJECT.MAX_NAME_LENGTH;
    this.MAX_DESC_LENGTH = PROJECT.MAX_DESC_LENGTH;

    this.NAME_LABEL = PROJECT.CREATE_PROJECT.NAME_LABEL;
    this.NAME_ERROR = PROJECT.CREATE_PROJECT.NAME_ERROR;
    this.DESC_LABEL = PROJECT.CREATE_PROJECT.DESC_LABEL;
    this.DESC_ERROR = PROJECT.CREATE_PROJECT.DESC_ERROR;
    this.BUTTON_SUBMIT = PROJECT.CREATE_PROJECT.BUTTON_SUBMIT;

    if (!this.countProjects) {
      this.$store
        .dispatch("FETCH_PROJECTS")
        .catch(err => this.$notifyError(errorHandler(err)));
    }
  },
  computed: {
    countProjects() {
      return this.$store.getters["GET_LIST_PROJECTS"].length;
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.pendingForm = true;
          this.$store
            .dispatch("CREATE_PROJECTS", {
              name: this.name,
              description: this.desc,
              privacy: "public",
              overwrite: true
            })
            .then(() => {
              this.$notifySuccess(`Project: "${this.name}" is created`);
              this.$router.push({ name: "Projects" });
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingForm = false;
            });
        } else {
          this.$refs.form.validate();
        }
      });
    }
  }
};
</script>
