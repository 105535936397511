<template>
  <header class="header">
    <div class="header__container">
      <div class="header__logo">
        <img
          v-if="!hasSidebar"
          src="@/assets/img/logo.png"
          width="275"
          alt="img"
        />
      </div>
      <div class="header__title text-weight-500">
        Signal<span class="text-primary">F</span>orge: News Monitoring &
        Analysis
      </div>
      <div v-if="isLoggedIn" class="text-right">
        <md-button type="button" class="-md-outline md-primary" @click="logout">
          <md-icon>login</md-icon>
          <span>logout</span>
        </md-button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    hasSidebar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["IS_LOGGED_IN"];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT").then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>
