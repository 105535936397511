<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$http.interceptors.response.use(
      res => res,
      err => {
        return new Promise((resolve, reject) => {
          const status = err.response ? err.response.status : null;
          if (status && status === 504 && !err.config.retry) {
            return this.$http({
              ...err.config,
              retry: true
            })
              .then(resolve)
              .catch(reject);
          }
          if (status && status === 401 && err.config) {
            this.$store.dispatch("LOGOUT").then(() => {
              this.$router.push("/");
            });
          }
          reject(err);
        });
      }
    );
  }
};
</script>
