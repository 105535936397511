<template>
  <md-card
    class="md-card-product"
    :data-count="hoverCount"
    @mouseleave.native="onMouseLeave"
  >
    <md-card-header
      :data-header-animation="headerAnimation"
      :class="[
        { hovered: imgHovered },
        { hinge: headerDown },
        { fadeInDown: fixedHeader }
      ]"
      class="animated md-card-header-image"
      @mouseenter.native="onMouseOver"
    >
      <slot name="imageHeader"></slot>
    </md-card-header>

    <md-card-content>
      <div
        v-if="headerAnimation === 'true'"
        class="md-card-action-buttons text-center"
      >
        <md-button
          v-if="headerDown"
          class="fix-broken-card md-danger md-simple"
          @click="fixHeader"
        >
          <slot name="fixed-button"></slot> Fix Header!
        </md-button>
        <md-button class="md-just-icon md-simple">
          <slot name="first-button"></slot>
        </md-button>
        <md-button class="md-just-icon md-simple md-success">
          <slot name="second-button"></slot>
        </md-button>
        <md-button class="md-danger md-just-icon md-simple">
          <slot name="third-button"></slot>
        </md-button>
      </div>
      <slot name="title"></slot>
      <slot name="description"></slot>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    headerAnimation: {
      type: String,
      default: "true"
    }
  },
  data() {
    return {
      hoverCount: 0,
      imgHovered: false,
      fixedHeader: false
    };
  },
  computed: {
    headerDown() {
      return this.hoverCount > 15;
    }
  },
  methods: {
    headerBack: function() {
      this.fixedHeader = false;
    },
    fixHeader: function() {
      this.hoverCount = 0;
      this.fixedHeader = true;

      setTimeout(this.headerBack, 480);
    },
    onMouseOver: function() {
      if (this.headerAnimation === "true") {
        this.imgHovered = true;
        this.hoverCount++;
      }
    },
    onMouseLeave: function() {
      if (this.headerAnimation === "true") {
        this.imgHovered = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
