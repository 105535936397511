import axios from "axios";

export default {
  state: {
    newsAll: [],
    type: [],
    typeFormat: [],
    newsPage: 1,
    pendingNews: false,
    firstLoad: true,
    currentId: ""
  },
  mutations: {
    SET_CURRENT_ID(state, id) {
      state.currentId = id;
    },
    GET_NEWS_TYPE_FORMAT(state, type) {
      let obj = {};
      type.forEach(item => {
        const category = item.category;
        if (!obj[category]) {
          obj[category] = [];
        }
        obj[category].push({ ...item });
      });
      state.typeFormat = obj;
    },
    SET_NEWS_TYPE(state, type) {
      state.type = type;
    },
    ADD_NEWS(state, { news, pageIndex }) {
      const newArr = [...state.newsAll];

      if (newArr[pageIndex]) {
        return;
      }

      let listID = "";
      const filteredNews = news.items.filter(item => {
        const isRepeated = listID.includes(item.user_news_item_id);
        if (!isRepeated) {
          listID += `,${item.user_news_item_id}`;
        }
        return !isRepeated;
      });

      newArr[pageIndex] = {
        ...news,
        items: filteredNews
      };
      state.newsAll = newArr;
    },
    DELETE_NEWS(state) {
      state.newsAll = [];
      state.pendingNews = false;
      state.firstLoad = true;
      state.currentId = "";
    },
    SET_NEWS_PAGE(state, number) {
      state.newsPage = number;
    },
    SET_NEWS_STATUS(state, status) {
      state.pendingNews = status;
    },
    SET_FIRST_STATUS(state, status) {
      state.firstLoad = status;
    }
  },
  actions: {
    FETCH_NEWS({ commit, state, dispatch }, params) {
      let pageIndex = params.page_index;
      const pageLoad = 4;

      commit("SET_FIRST_STATUS", false);
      if (state.newsAll[pageIndex]) {
        if (state.newsAll[pageIndex + pageLoad]) {
          return;
        } else {
          pageIndex += pageLoad;
        }
      }

      commit("SET_NEWS_STATUS", true);
      commit("SET_CURRENT_ID", JSON.stringify(params));
      return axios
        .post("news", params)
        .then(res => {
          if (res.config.data !== state.currentId) {
            return;
          }
          commit("ADD_NEWS", { news: res.data, pageIndex: pageIndex });

          if (!res.data.is_last_page) {
            if (state.newsPage + pageLoad > state.newsAll.length) {
              dispatch("FETCH_NEWS", {
                ...params,
                page_index: pageIndex + 1
              });
            }
          }
        })
        .finally(() => {
          commit("SET_NEWS_STATUS", false);
        });
    },
    FETCH_QUERY_NEWS(
      { commit, state, dispatch },
      { projectId, queryId, page_index }
    ) {
      let pageIndex = page_index;
      const pageLoad = 4;

      commit("SET_FIRST_STATUS", false);
      if (state.newsAll[pageIndex]) {
        if (state.newsAll[pageIndex + pageLoad]) {
          return;
        } else {
          pageIndex += pageLoad;
        }
      }

      commit("SET_NEWS_STATUS", true);
      commit("SET_CURRENT_ID", queryId);
      return axios
        .get(`/projects/${projectId}/queries/${queryId}/news`, {
          params: { page_index }
        })
        .then(res => {
          if (res.config.url.indexOf(`/queries/${state.currentId}`) === -1) {
            return;
          }
          commit("ADD_NEWS", { news: res.data, pageIndex: pageIndex });

          if (!res.data.is_last_page) {
            if (state.newsPage + pageLoad > state.newsAll.length) {
              dispatch("FETCH_QUERY_NEWS", {
                projectId,
                queryId,
                page_index: pageIndex + 1
              });
            }
          }
        })
        .finally(() => {
          commit("SET_NEWS_STATUS", false);
        });
    },

    FETCH_PROJECT_NEWS({ commit, state, dispatch }, { projectId, page_index }) {
      let pageIndex = page_index;
      const pageLoad = 4;

      commit("SET_FIRST_STATUS", false);
      if (state.newsAll[pageIndex]) {
        if (state.newsAll[pageIndex + pageLoad]) {
          return;
        } else {
          pageIndex += pageLoad;
        }
      }

      commit("SET_NEWS_STATUS", true);
      commit("SET_CURRENT_ID", projectId);
      return axios
        .get(`/projects/${projectId}/news`, {
          params: { page_index: pageIndex }
        })
        .then(res => {
          if (res.config.url.indexOf(`/projects/${state.currentId}`) === -1) {
            return;
          }
          commit("ADD_NEWS", { news: res.data, pageIndex: pageIndex });

          if (!res.data.is_last_page) {
            if (state.newsPage + pageLoad > state.newsAll.length) {
              dispatch("FETCH_PROJECT_NEWS", {
                projectId,
                page_index: pageIndex + 1
              });
            }
          }
        })
        .finally(() => {
          commit("SET_NEWS_STATUS", false);
        });
    },

    SET_NEWS_INTERESTING_QUERY(_, { projectId, queryId, newsId, interesting }) {
      return axios.put(
        `/projects/${projectId}/queries/${queryId}/news/${newsId}`,
        {
          interesting: interesting
        }
      );
    },
    SET_NEWS_INTERESTING_PROJECT(_, { projectId, newsId, interesting }) {
      return axios.put(`/projects/${projectId}/news/${newsId}`, {
        interesting: interesting
      });
    },
    FETCH_NEWS_TYPE({ commit }) {
      return axios.post("news/input-sources?detailed=true").then(res => {
        commit("SET_NEWS_TYPE", res.data.engines);
        commit("GET_NEWS_TYPE_FORMAT", res.data.engines);
      });
    }
  },
  getters: {
    GET_NEWS: s => s.newsAll[s.newsPage - 1],
    GET_NEWS_ALL: s => s.newsAll,
    GET_NEWS_TYPE: s => s.type,
    GET_NEWS_TYPE_FORMAT: s => s.typeFormat,
    GET_NEWS_PAGE: s => s.newsPage,
    GET_NEWS_PENDING: s => s.pendingNews,
    IS_FIRST_LOADED: s => s.firstLoad,
    GET_CURRENT_ID: s => s.currentId
  }
};
