<template>
  <div class="wrapper">
    <notifications></notifications>

    <Header :has-sidebar="true" />
    <side-bar data-background-color="black">
      <!--      <user-menu></user-menu>-->

      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{
            name: `${projectInfo ? projectInfo.name : PROJECT_NAME_DEFAULT}`,
            icon: 'dashboard',
            iconOutline: true,
            path: `/project/${$route.params.id}`
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: CREATE_QUERY,
            icon: 'add_circle_outline',
            path: `/project/${$route.params.id}/query-new`
          }"
        ></sidebar-item>
        <hr />
        <h5 class="sidebar__subtitle">{{ PROJECT_QUERIES }}</h5>
        <sidebar-item
          v-for="(link, i) in queryList"
          :key="link.project_query_id"
          :link="{
            name: `${link.query_name ? link.query_name : `Query ${i + 1}`}`,
            desc: `${link.query_term ? link.query_term : ''}`,
            source_id: link.input_source_id,
            path: `/project/${$route.params.id}/query/${link.project_query_id}`
          }"
          tooltip
        ></sidebar-item>
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Query 1',-->
        <!--            icon: 'travel_explore',-->
        <!--            path: `/project/${$route.params.id}/query/1`-->
        <!--          }"-->
        <!--        ></sidebar-item>-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Query 2',-->
        <!--            icon: 'web',-->
        <!--            path: `/project/${$route.params.id}/query/2`-->
        <!--          }"-->
        <!--        ></sidebar-item>-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Query 3',-->
        <!--            icon: 'search',-->
        <!--            path: `/project/${$route.params.id}/query/3`-->
        <!--          }"-->
        <!--        ></sidebar-item>-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Query 4',-->
        <!--            icon: 'rss_feed',-->
        <!--            path: `/project/${$route.params.id}/query/4`-->
        <!--          }"-->
        <!--        ></sidebar-item>-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Query 5',-->
        <!--            icon: 'speaker_notes',-->
        <!--            iconOutline: true,-->
        <!--            path: `/project/${$route.params.id}/query/5`-->
        <!--          }"-->
        <!--        ></sidebar-item>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Common/Header";
import MobileMenu from "@/pages/Dashboard/Layout/Extra/MobileMenu";
import errorHandler from "@/utils/errorHandler";
import { SIDEBAR } from "@/utils/constants";

export default {
  name: "Sidebar",
  components: { Header, MobileMenu },
  created() {
    this.PROJECT_NAME_DEFAULT = SIDEBAR.PROJECT_NAME_DEFAULT;
    this.CREATE_QUERY = SIDEBAR.CREATE_QUERY;
    this.PROJECT_QUERIES = SIDEBAR.PROJECT_QUERIES;
  },
  mounted() {
    this.$store.commit("RESET_PROJECT");
    this.$store.commit("RESET_QUERY_LIST");

    if (!this.projectInfo) {
      this.$store
        .dispatch("FETCH_PROJECT", this.$route.params.id)
        .catch(err => this.$notifyError(errorHandler(err)));
    }
    if (!this.queryList) {
      this.$store
        .dispatch("FETCH_QUERY_LIST", this.$route.params.id)
        .catch(err => this.$notifyError(errorHandler(err)));
    }
    if (!this.reportList) {
      this.$store
        .dispatch("FETCH_REPORTS", this.$route.params.id)
        .catch(err => this.$notifyError(errorHandler(err)));
    }
    if (!this.newsType?.length) {
      this.$store
        .dispatch("FETCH_NEWS_TYPE")
        .catch(err => this.$notifyError(errorHandler(err)));
    }
  },
  beforeDestroy() {
    this.$store.commit("DELETE_NEWS");
  },
  computed: {
    projectInfo() {
      return this.$store.getters["GET_PROJECT"];
    },
    newsType() {
      return this.$store.getters["GET_NEWS_TYPE"];
    },
    queryList() {
      return this.$store.getters["GET_QUERY_LIST"];
    },
    reportList() {
      return this.$store.getters["GET_REPORT"];
    },
    queryID() {
      return this.$route.params.query;
    },
    projectId() {
      return this.$route.params.id;
    }
  },
  watch: {
    queryID() {
      this.$store.commit("DELETE_NEWS");
    },
    projectId() {
      this.$store.commit("DELETE_NEWS");
    }
  },
  methods: {
    toggleSidebar() {}
    // findIcon(id) {
    //   // inputSourceId() {
    //   //   const id = this.link.source_id;
    //   const item = this.newsType.find(item => item.engine_id === id);
    //   const icon = item ? `${item?.category}, ${item?.name}` : "";
    //
    //   return "travel_explore";
    //   // },
    // }
  }
};
</script>
