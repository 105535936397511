<template>
  <div class="main">
    <ProjectBreadcrumbs />

    <Preloader v-if="pendingQuery" text="Loading query..." />

    <div v-if="!pendingQuery" class="md-layout">
      <div
        class="md-layout-item md-medium-size-80 md-size-65 md-xsmall-size-100 mx-auto"
      >
        <md-card>
          <md-card-header>
            <div class="h4 mt-0 text-weight-400 title">
              Query: {{ nameQuery }}
            </div>
          </md-card-header>
          <md-card-content>
            <md-table>
              <md-table-row v-for="item in queryDetails" :key="item.name">
                <md-table-cell>{{ item.name }}</md-table-cell>
                <md-table-cell>{{ item.desc }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions class="flex-wrap justify-content-between">
            <md-button
              class="md-danger md-simple"
              :disabled="disabledBtn"
              @click="showDeleteModal"
            >
              <md-icon class="md-outline">delete_forever</md-icon>
              delete
            </md-button>
            <md-button
              class="md-outline md-secondary"
              :disabled="disabledBtn"
              @click="editQuery"
            >
              <md-icon class="md-outline">create</md-icon>
              edit
            </md-button>
            <md-button
              class="md-outline md-secondary"
              :disabled="disabledBtn"
              @click="copyQuery"
            >
              <md-icon class="md-outline">content_copy</md-icon>
              copy to a new query
            </md-button>
            <md-button
              class="md-outline md-secondary"
              :disabled="disabledBtn"
              @click="getNews"
            >
              <ButtonLoader
                text="refresh results"
                icon="refresh"
                icon-outline
                accent
              />
            </md-button>
            <md-button
              class="md-just-icon md-outline md-secondary"
              @click="queryDetailsShow = !queryDetailsShow"
            >
              <md-icon class="md-outline md-size-1-5x">
                <template v-if="queryDetailsShow">expand_less</template>
                <template v-else>expand_more</template>
              </md-icon>
            </md-button>
          </md-card-actions>
        </md-card>
      </div>
    </div>

    <modal
      v-if="isShowDeleteModal"
      class="modal-footer-hide"
      @close="hideDeleteModal"
    >
      <template slot="header">
        <h4 class="modal-title text-center text-weight-400">
          {{ ALERT_TITLE }} "{{ nameQuery }}"?
        </h4>
      </template>
      <template slot="body">
        <p>{{ ALERT_DESC }}</p>

        <div class="md-layout">
          <div class="md-layout-item md-size-70 md-xsmall-size-100 mx-auto p-0">
            <div
              class="align-items-center d-flex justify-content-around mt-4 mx-auto text-center"
            >
              <md-button
                type="submit"
                class="md-danger"
                :disabled="disabledBtn"
                @click="deleteQuery"
              >
                <ButtonLoader text="Delete" :is-load="pendingDelete" />
              </md-button>
              <md-button
                type="submit"
                class="md-outline"
                :disabled="disabledBtn"
                @click="hideDeleteModal"
              >
                cancel
              </md-button>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <ArticlesList show-actions />
  </div>
</template>

<script>
import ProjectBreadcrumbs from "@/components/Project/ProjectBreadcrumbs";
import { formatDate } from "@/utils/formatDate";
import { Modal } from "@/components";
import ButtonLoader from "@/components/Common/Button-loader";
import Preloader from "@/components/Common/Preloader";
import { anyToString } from "@/utils/string";
import errorHandler from "@/utils/errorHandler";
import ArticlesList from "@/components/Articles/Articles-list";
import { QUERY } from "@/utils/constants";

export default {
  name: "QueryItem",
  components: {
    ArticlesList,
    ProjectBreadcrumbs,
    Modal,
    ButtonLoader,
    Preloader
  },
  data() {
    return {
      queryDetailsShow: false,
      isShowDeleteModal: false,
      pendingDelete: false,
      scopeOptions: [
        {
          value: "",
          label: "Anywhere in the page"
        },
        {
          value: "allintitle",
          label: "In the title of page"
        },
        {
          value: "allintext",
          label: "In the text of page"
        }
      ]
    };
  },
  created() {
    this.ALERT_TITLE = QUERY.DELETE.ALERT_TITLE;
    this.ALERT_DESC = QUERY.DELETE.ALERT_DESC;
  },
  mounted() {
    this.getNews();
  },
  computed: {
    newsInfo() {
      return this.$store.getters["GET_NEWS"];
    },
    showNews() {
      return this.newsInfo?.items.length;
    },
    isLastPage() {
      return this.newsInfo?.is_last_page ?? true;
    },
    pendingNews() {
      return this.$store.getters["GET_NEWS_PENDING"];
    },
    disabledBtn() {
      return this.pendingNews;
    },
    pendingQuery() {
      return this.infoQuery === undefined;
    },
    infoQuery() {
      return this.queryList
        ? this.queryList.find(item => item.project_query_id === +this.queryID)
        : undefined;
    },
    nameQuery() {
      return this.infoQuery.query_name
        ? this.infoQuery.query_name
        : this.queryID;
    },
    queryDetails() {
      const arr = [
        {
          name: QUERY.TERM,
          desc: anyToString(this.infoQuery?.query_term)
        },
        {
          name: QUERY.NEWS_GROUP,
          desc: this.inputSourceId
        },
        {
          name: QUERY.FACET,
          desc: ""
        },
        {
          name: QUERY.SITE,
          desc: this.infoQuery?.site || "N/A"
        },
        {
          name: QUERY.SCOPE,
          desc: this.formattedScope
        },
        {
          name: QUERY.ADDED,
          desc: formatDate(this.infoQuery?.updated_at)
        },
        {
          name: QUERY.ARTICLES,
          desc: ""
        }
      ];
      return this.queryDetailsShow ? arr : arr.slice(0, 2);
    },
    queryList() {
      return this.$store.getters["GET_QUERY_LIST"];
    },
    newsType() {
      return this.$store.getters["GET_NEWS_TYPE"];
    },
    inputSourceId() {
      const id = this.infoQuery?.input_source_id;
      const item = this.newsType.find(item => item.engine_id === id);
      return item ? `${item?.category}, ${item?.name}` : id;
    },
    queryID() {
      return this.$route.params.query;
    },
    projectId() {
      return this.$route.params.id;
    },
    paramsForm() {
      return {
        name: this.infoQuery.query_name,
        type: this.infoQuery.input_source_id,
        termQuery: this.infoQuery.query_term,
        site: this.infoQuery.site,
        scope: this.infoQuery.search_scope
      };
    },
    formattedScope() {
      const str = anyToString(this.infoQuery?.search_scope);
      return this.scopeOptions.find(item => item.value === str).label;
    },
    currentPage: {
      get() {
        return this.$store.getters["GET_NEWS_PAGE"];
      },
      set(val) {
        this.$store.commit("SET_NEWS_PAGE", val);
      }
    }
  },
  watch: {
    queryID() {
      this.queryDetailsShow = false;
      if (this.currentPage === 1) {
        this.getNews();
      } else {
        this.currentPage = 1;
      }
    },
    currentPage() {
      this.getNews();
    }
  },
  methods: {
    showDeleteModal() {
      this.isShowDeleteModal = true;
    },
    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },
    deleteQuery() {
      this.pendingDelete = true;
      this.$store
        .dispatch("DELETE_QUERY", {
          projectId: this.projectId,
          queryID: this.queryID
        })
        .then(() => {
          this.$notifySuccess(`${QUERY.DELETE.SUCCESS} ${this.nameQuery}`);
          this.hideDeleteModal();
          this.pendingDelete = true;
          this.$store
            .dispatch("FETCH_QUERY_LIST", this.$route.params.id)
            .then(() => {
              this.$router.push({
                path: `/project/${this.$route.params.id}`
              });
            })
            .finally(() => {
              this.pendingDelete = false;
            });
        })
        .catch(err => this.$notifyError(errorHandler(err)))
        .finally(() => {
          this.pendingDelete = false;
        });
    },
    getNews() {
      this.$store
        .dispatch("FETCH_QUERY_NEWS", {
          projectId: this.projectId,
          queryId: this.queryID,
          page_index: this.currentPage - 1
        })
        .catch(err => this.$notifyError(errorHandler(err)));
    },
    copyQuery() {
      this.$store.commit("SET_QUERY_FORM", this.paramsForm);
      this.$router.push({ name: "QueryNew" });
    },
    editQuery() {
      this.$store.commit("SET_QUERY_FORM", this.paramsForm);
      this.$router.push({ name: "QueryEdit" });
    }
  }
};
</script>
