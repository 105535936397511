<template>
  <md-card>
    <md-card-header>
      <div class="h4 mt-0 text-weight-400 title">{{ TITLE }}</div>
    </md-card-header>
    <md-card-content>
      <ValidationObserver v-if="detailsEdit" ref="form">
        <form class="" @submit.prevent="update">
          <ValidationProvider
            v-slot="{ passed, failed }"
            mode="eager"
            class="d-flex mb-4"
            rules="required"
          >
            <md-field
              class="mb-3 mt-0"
              :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
            >
              <label>{{ NAME_LABEL }}</label>
              <md-input
                v-model="name"
                type="text"
                placeholder=""
                :maxlength="maxNameLength"
              ></md-input>
              <span
                class="md-helper-error md-helper-text"
                :class="{ show: failed }"
              >
                {{ NAME_ERROR }}
              </span>
              <slide-y-down-transition>
                <md-icon v-show="failed" class="error">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon v-show="passed" class="success">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ passed, failed }"
            mode="eager"
            class="d-flex mb-4 pb-2"
            rules="required"
          >
            <md-field
              class="mb-0 mt-0"
              :class="{ 'md-error': failed, 'md-valid': passed }"
            >
              <label>{{ DESC_LABEL }}</label>
              <md-textarea
                v-model="desc"
                :maxlength="maxDescLength"
                md-autogrow
              ></md-textarea>
              <span
                class="md-helper-error md-helper-text"
                :class="{ show: failed }"
              >
                {{ DESC_ERROR }}
              </span>
              <slide-y-down-transition>
                <md-icon v-show="failed" class="error">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon v-show="passed" class="success">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>
        </form>
      </ValidationObserver>

      <md-table :class="{ 'opacity-3': detailsEdit }">
        <md-table-row
          v-for="item in projectDetails.slice(detailsEdit ? 2 : 0)"
          :key="item.name"
        >
          <md-table-cell>{{ item.name }}</md-table-cell>
          <md-table-cell>{{ item.desc }}</md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions>
      <template v-if="detailsEdit">
        <md-button
          key="detailsEdit_close"
          class="md-danger md-just-icon md-round mr-4"
          :disabled="pendingUpdate"
          @click="close"
        >
          <md-icon class="md-outline">close</md-icon>
        </md-button>
        <md-button
          key="detailsEdit_check"
          class="md-just-icon md-primary md-round mr-4"
          :disabled="pendingUpdate"
          @click="update"
        >
          <ButtonLoader :is-load="pendingUpdate" icon="check" icon-outline />
        </md-button>
      </template>
      <template v-else>
        <md-button class="md-outline md-secondary" @click="edit">
          <md-icon class="md-outline">edit</md-icon>
          Edit
        </md-button>
      </template>

      <md-button
        class="md-just-icon md-outline md-secondary"
        @click="detailsShow = !detailsShow"
      >
        <md-icon class="md-outline md-size-1-5x">
          <template v-if="detailsShow">expand_less</template>
          <template v-else>expand_more</template>
        </md-icon>
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
import ButtonLoader from "@/components/Common/Button-loader";
import { PROJECT, PROJECT_DETAILS } from "@/utils/constants";
import { sliceText, anyToString } from "@/utils/string";
import { formatDate } from "@/utils/formatDate";
import { required, length } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import errorHandler from "@/utils/errorHandler";

extend("required", required);
extend("length", length);

export default {
  name: "ProjectDetails",
  components: { ButtonLoader, SlideYDownTransition },
  data() {
    return {
      detailsShow: false,
      detailsEdit: false,
      name: this.info?.name,
      desc: this.info?.description,
      pendingUpdate: false,
      maxNameLength: PROJECT.MAX_NAME_LENGTH,
      maxDescLength: PROJECT.MAX_DESC_LENGTH
    };
  },
  created() {
    this.TITLE = PROJECT_DETAILS.TITLE;
    this.NAME_LABEL = PROJECT_DETAILS.FORM.NAME_LABEL;
    this.NAME_ERROR = PROJECT_DETAILS.FORM.NAME_ERROR;
    this.DESC_LABEL = PROJECT_DETAILS.FORM.DESC_LABEL;
    this.DESC_ERROR = PROJECT_DETAILS.FORM.DESC_ERROR;
  },
  mounted() {
    this.setDefaultData();
  },
  computed: {
    info() {
      return this.$store.getters["GET_PROJECT"];
    },
    projectDetails() {
      const arr = [
        {
          name: PROJECT_DETAILS.NAME,
          desc: sliceText(this.info?.name, PROJECT.MAX_NAME_LENGTH, true)
        },
        {
          name: PROJECT_DETAILS.DESC,
          desc: sliceText(this.info?.description, PROJECT.MAX_DESC_LENGTH, true)
        },
        {
          name: PROJECT_DETAILS.OWNER,
          desc: anyToString(this.info?.owner)
        },
        {
          name: PROJECT_DETAILS.LAST_UPDATE,
          desc: formatDate(this.info?.last_updated_at)
        },
        {
          name: PROJECT_DETAILS.TOTAL_ARTICLES,
          desc: anyToString(this.info?.article_count)
        }
      ];
      return this.detailsShow ? arr : arr.slice(0, 2);
    }
  },
  watch: {
    info() {
      this.setDefaultData();
    }
  },
  methods: {
    setDefaultData() {
      this.name = this.info?.name;
      this.desc = this.info?.description;
    },
    edit() {
      this.detailsShow = true;
      this.detailsEdit = true;
    },
    close() {
      this.setDefaultData();
      this.detailsShow = false;
      this.detailsEdit = false;
    },
    update() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.pendingUpdate = true;

          this.$store
            .dispatch("UPDATE_PROJECT", {
              id: this.$route.params.id,
              param: { name: this.name, description: this.desc }
            })
            .then(() => {
              this.$notifySuccess(PROJECT_DETAILS.SUCCESS_SAVE);
              this.close();
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingUpdate = false;
            });
        } else {
          this.$refs.form.validate();
        }
      });
    }
  }
};
</script>
