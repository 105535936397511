import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import VueBreadcrumbs from "vue-2-breadcrumbs";
import store from "./store";
import axios from "axios";
import { BASE_URL } from "@/utils/constants";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import router from "./routes/index";

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueBreadcrumbs);

// global library setup
Vue.prototype.$Chartist = Chartist;
Vue.prototype.$http = axios;

Vue.prototype.$http.defaults.baseURL = BASE_URL;

const token = localStorage.getItem("access_token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["X-GRI-ACCESS-TOKEN"] = token;
}

Vue.config.errorHandler = err => {
  if (process.env.NODE_ENV !== "production") {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined") {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store
});
