<template>
  <div class="main">
    <Preloader v-if="pendingProject" :text="LOADING_PROJECT" />

    <template v-if="info">
      <ProjectBreadcrumbs />

      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <ProjectDetails />
        </div>

        <div class="md-layout-item md-size-50">
          <AnalysisDetails />
        </div>
      </div>

      <div v-if="firstReportUrl" class="md-layout">
        <div class="md-layout-item md-size-100">
          <div class="md-card mt-0">
            <div class="md-card-content">
              <iframe
                class="projects__iframe"
                width="100%"
                :src="firstReportUrl"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <ArticlesList v-if="false" show-actions is-project />
    </template>
    <template v-if="!info && !pendingProject">
      <ProjectBreadcrumbs />

      <div class="md-layout mt-4">
        <div class="md-layout-item md-size-100">
          <div class="articles">
            <div class="h4 mt-3 text-weight-400 title">
              {{ NOT_LOADED }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectBreadcrumbs from "@/components/Project/ProjectBreadcrumbs";
import Preloader from "@/components/Common/Preloader";
import ProjectDetails from "@/components/Project/ProjectDetails";
import AnalysisDetails from "@/components/Project/AnalysisDetails";
import errorHandler from "@/utils/errorHandler";
import { PROJECT } from "@/utils/constants";
import ArticlesList from "@/components/Articles/Articles-list";

export default {
  name: "Project",
  components: {
    ArticlesList,
    AnalysisDetails,
    ProjectDetails,
    Preloader,
    ProjectBreadcrumbs
  },
  created() {
    this.NOT_LOADED = PROJECT.NOT_LOADED;
    this.LOADING_PROJECT = PROJECT.LOADING_PROJECT;
  },
  mounted() {
    this.getNews();
  },
  computed: {
    pendingNews() {
      return this.$store.getters["GET_NEWS_PENDING"];
    },
    newsInfo() {
      return this.$store.getters["GET_NEWS"];
    },
    allNews() {
      return this.$store.getters["GET_NEWS_ALL"];
    },
    totalItems() {
      return this.newsInfo?.total_items ?? 1;
    },
    pendingProject() {
      return this.$store.getters["STATUS_PROJECT"];
    },
    info() {
      return this.$store.getters["GET_PROJECT"];
    },
    showNews() {
      return this.newsInfo?.items.length;
    },
    reports() {
      return this.$store.getters["GET_REPORT"];
    },
    firstReportUrl() {
      return this.reports?.[0]?.gds_link;
    },
    isLastPage() {
      return this.newsInfo?.is_last_page ?? true;
    },
    currentPage: {
      get() {
        return this.$store.getters["GET_NEWS_PAGE"];
      },
      set(val) {
        this.$store.commit("SET_NEWS_PAGE", val);
      }
    },
    projectId() {
      return this.$route.params.id;
    }
  },
  watch: {
    currentPage() {
      this.getNews();
    }
  },
  methods: {
    getNews() {
      this.$store
        .dispatch("FETCH_PROJECT_NEWS", {
          projectId: this.projectId,
          page_index: this.currentPage - 1
        })
        .catch(err => this.$notifyError(errorHandler(err)));
    }
  }
};
</script>
