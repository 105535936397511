<template>
  <div
    v-show="active"
    :id="tabId"
    class="fade tab-pane"
    role="tabpanel"
    :aria-hidden="!active"
    :aria-labelledby="`step-${tabId}`"
    :class="{ 'active show': active }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WizardTab",
  inject: ["addTab", "removeTab"],
  props: {
    // eslint-disable-next-line vue/no-unused-properties,vue/require-default-prop
    label: String,
    // eslint-disable-next-line vue/no-unused-properties,vue/require-default-prop
    id: String,
    // eslint-disable-next-line vue/no-unused-properties,vue/require-default-prop
    beforeChange: Function
  },
  data() {
    return {
      active: false,
      checked: false,
      hasError: false,
      tabId: ""
    };
  },
  mounted() {
    this.addTab(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  }
};
</script>

<style></style>
