import DefaultLayout from "@/layouts/DefaultLayout";
import ProjectLayout from "@/layouts/ProjectLayout";

import Login from "@/pages/Login";
import Projects from "@/pages/Projects";
import ProjectNew from "@/pages/Project-new";
import Project from "@/pages/Project";
import QueryNew from "@/pages/Query-new";
import QueryItem from "@/pages/Query-item";
import QueryEdit from "@/pages/Query-edit";

// import {
//   componentsMenu,
//   formsMenu,
//   tablesMenu,
//   mapsMenu,
//   pagesMenu,
//   authPages,
//   mainPages
// } from "./dashboard";

const routes = [
  {
    path: "/",
    name: "",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Login",
        component: Login
      },
      {
        path: "/projects",
        name: "Projects",
        component: Projects,
        meta: {
          breadcrumb: "Projects",
          requiresAuth: true
        }
      },
      {
        path: "/project-new",
        name: "ProjectNew",
        component: ProjectNew,
        meta: {
          breadcrumb: {
            label: "New Project",
            parent: "Projects"
          },
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: "/project",
    name: "ProjectLayout",
    component: ProjectLayout,
    children: [
      {
        path: ":id",
        name: "Project",
        component: Project,
        meta: {
          breadcrumb(params) {
            return {
              label: params.name ? params.name : params.id,
              parent: "Projects"
            };
          },
          requiresAuth: true
        }
      },
      {
        path: ":id/query-new",
        name: "QueryNew",
        component: QueryNew,
        meta: {
          breadcrumb() {
            return {
              label: "Create New Query",
              parent: "Project"
            };
          },
          requiresAuth: true
        }
      },
      {
        path: ":id/query/:query",
        name: "QueryItem",
        component: QueryItem,
        meta: {
          breadcrumb(params) {
            return {
              label: params.queryName ? params.queryName : params.query,
              parent: "Project"
            };
          },
          requiresAuth: true
        }
      },
      {
        path: ":id/query/:query/edit",
        name: "QueryEdit",
        component: QueryEdit,
        meta: {
          breadcrumb() {
            return {
              label: "Edit",
              parent: "QueryItem"
            };
          },
          requiresAuth: true
        }
      }
    ]
  },

  // componentsMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  // pagesMenu,
  // authPages,
  // mainPages,
  {
    path: "*",
    component: Login
  }
];

export default routes;
