<template>
  <div :key="title" class="main">
    <ProjectBreadcrumbs />

    <div v-if="infoQuery">
      <QueryForm :title="title" edit />

      <ArticlesList />
    </div>
  </div>
</template>

<script>
import ProjectBreadcrumbs from "@/components/Project/ProjectBreadcrumbs";
import QueryForm from "@/components/Query/QueryForm";
import ArticlesList from "@/components/Articles/Articles-list";

export default {
  name: "QueryEdit",
  components: {
    ArticlesList,
    ProjectBreadcrumbs,
    QueryForm
  },
  computed: {
    queryList() {
      return this.$store.getters["GET_QUERY_LIST"];
    },
    infoQuery() {
      return this.queryList
        ? this.queryList.find(item => item.project_query_id === +this.queryID)
        : undefined;
    },
    title() {
      return (
        this.infoQuery?.query_name || this.infoQuery?.query_term || "Query"
      );
    },
    queryID() {
      return this.$route.params.query;
    },
    paramsForm() {
      return {
        name: this.infoQuery?.query_name,
        type: this.infoQuery?.input_source_id,
        termQuery: this.infoQuery?.query_term,
        site: this.infoQuery?.site,
        scope: this.infoQuery?.search_scope
      };
    }
  },
  watch: {
    infoQuery() {
      this.$store.commit("SET_QUERY_FORM", this.paramsForm);
    }
  }
};
</script>
