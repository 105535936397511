import { StatusCodes } from "./httpStatusCodes";

export default error => {
  let status = "";
  if (error.message === "Network Error") {
    status = "NetworkError";
  } else {
    status = error.response?.status || error.data?.error; // jshint ignore:line
  }
  return StatusCodes[status] ?? JSON.stringify(error.data); // jshint ignore:line
};
