<template>
  <div class="preloader">
    <div class="preloader__content">
      <Spinner :size="12" />
      <h4 class="preloader__title text-white">{{ text }}</h4>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Common/Spinner";

export default {
  name: "Preloader",
  components: { Spinner },
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
