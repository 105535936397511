<template>
  <div :key="NEW_QUERY_TITLE" class="main">
    <ProjectBreadcrumbs />

    <QueryForm />

    <ArticlesList />
  </div>
</template>

<script>
import ProjectBreadcrumbs from "@/components/Project/ProjectBreadcrumbs";
import QueryForm from "@/components/Query/QueryForm";
import ArticlesList from "@/components/Articles/Articles-list";
import { QUERY } from "@/utils/constants";

export default {
  name: "QueryAdd",
  components: {
    ArticlesList,
    ProjectBreadcrumbs,
    QueryForm
  },
  created() {
    this.NEW_QUERY_TITLE = QUERY.NEW_QUERY_TITLE;
  }
};
</script>
