<template>
  <div
    class="mb-5 md-layout-item md-medium-size-50 md-size-25 md-xsmall-size-100"
  >
    <div class="projects__item">
      <router-link
        :to="{
          name: 'Project',
          params: { id: item.id, name: item.name }
        }"
        class="md-hidden-link"
      ></router-link>
      <drop-down class="md-z-index-3 projects__toggle" direction="down">
        <md-button
          slot="title"
          class="md-just-icon md-outline"
          data-toggle="dropdown"
        >
          <md-icon>more_horiz</md-icon>
        </md-button>
        <ul class="dropdown-menu dropdown-menu-right">
          <li>
            <router-link
              :to="{
                name: 'Project',
                params: { id: item.id, name: item.name }
              }"
            >
              <md-icon>dashboard</md-icon>
              Manage Project
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'QueryNew',
                params: { id: item.id, name: item.name }
              }"
            >
              <md-icon>add_circle_outline</md-icon>
              Add New Query
            </router-link>
          </li>
          <li>
            <a href="#">
              <md-icon>create</md-icon>
              Edit Project Details
            </a>
          </li>
          <li>
            <a href="#">
              <md-icon>explore</md-icon>
              Explore Articles
            </a>
          </li>
          <li v-if="false">
            <a href="#">
              <md-icon>delete_outline</md-icon>
              Delete Project
            </a>
          </li>
        </ul>
      </drop-down>
      <div class="hyphenate projects__title">{{ item.name }}</div>
      <div class="hyphenate projects__desc">{{ item.description }}</div>
      <div v-if="false" class="projects__bottom">
        <div class="projects__info">
          <md-icon class="color-parent md-outline">assignment</md-icon>
          <span>0 Articles</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
