<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{ active: isActive }"
    tag="li"
  >
    <a
      v-if="isMenu"
      href="#"
      class="nav-link sidebar-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <md-icon :class="{ 'md-outline': link.iconOutline }">
        {{ link.icon }}
      </md-icon>
      <p>
        {{ link.name }}
        <b class="caret"></b>
      </p>
    </a>

    <collapse-transition>
      <div v-if="$slots.default || isMenu" v-show="!collapsed">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot
      v-if="children.length === 0 && !$slots.default && link.path"
      name="title"
    >
      <component
        :is="elementType(link, false)"
        :to="link.path"
        :class="{ active: link.active }"
        class="nav-link"
        :target="link.target"
        :href="link.path"
        exact
        @click.native="linkClick"
      >
        <template v-if="addLink">
          <span class="sidebar-mini">{{ linkPrefix }}</span>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <md-icon :class="{ 'md-outline': link.iconOutline }">
            {{ findIcon }}
          </md-icon>
          <p>{{ link.name }}</p>
        </template>
        <template v-if="tooltip">
          <md-icon class="md-outline sidebar__info">info</md-icon>
          <md-tooltip class="sidebar__tooltip" md-direction="right">
            <b>{{ link.name }}</b>
            <span>{{ link.desc }}</span>
            <small>{{ inputSourceId }}</small>
          </md-tooltip>
        </template>
      </component>
    </slot>
  </component>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "SidebarItem",
  components: {
    CollapseTransition
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true
    }
  },
  props: {
    menu: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
          iconOutline: false
        };
      }
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      children: [],
      collapsed: true
    };
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  destroyed() {
    this.$sidebar.removeSidebarLink(this);
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  computed: {
    newsType() {
      return this.$store.getters["GET_NEWS_TYPE"];
    },
    inputSourceId() {
      const id = this.link.source_id;
      const item = this.newsType.find(item => item.engine_id === id);
      return item ? `${item?.category}, ${item?.name}` : id;
    },
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(" ");
        return words.map(word => word.substring(0, 1)).join("");
      }
      return false;
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find(c =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
    findIcon() {
      if (this.link.icon) {
        return this.link.icon;
      }
      const id = this.link.source_id;
      const item = this.newsType.find(item => item.engine_id === id);
      const name = item?.name;
      const category = item?.category;
      if (name === "Social Media") return "group";
      if (name === "Feeds") return "rss_feed";
      if (category === "Specialty") return "collections_bookmark";
      if (category === "External") return "search";

      return "travel_explore";
    }
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join("");
    },
    linkClick() {
      if (!this.addLink) {
        this.$sidebar.collapseAllMenus();
      }

      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      if (this.collapsed) {
        this.$sidebar.addSidebarLink(this);
        this.$sidebar.collapseAllMenus();
      }

      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    }
  }
};
</script>

<style>
.sidebar-menu-item {
  cursor: pointer;
}
</style>
