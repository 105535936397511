<template>
  <span
    class="align-items-center d-flex md-button-loader"
    :class="{
      'md-button-loader--active': isLoad,
      'md-button-loader--center': center
    }"
  >
    <md-icon v-if="icon.length > 0" :class="iconClass">
      {{ icon }}
    </md-icon>
    {{ text }}
    <span class="md-button-loader_spinner">
      <Spinner :class="{ 'md-accent': accent }" :size="loaderSize" />
    </span>
  </span>
</template>

<script>
import Spinner from "@/components/Common/Spinner";

export default {
  name: "ButtonLoader",
  components: { Spinner },
  props: {
    text: {
      type: String,
      default: ""
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    iconClass: {
      type: String,
      default: ""
    },
    loaderSize: {
      type: Number,
      default: 3
    },
    accent: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  }
};
</script>
