var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(!_vm.countProjects)?_c('Preloader',{attrs:{"text":"Loading projects..."}}):[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('Breadcrumbs')],1)]),(_vm.countProjects < _vm.MAX_COUNT)?[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-90 md-size-70 md-small-size-100 mx-auto"},[_c('div',{staticClass:"h2"},[_vm._v(_vm._s(_vm.TITLE))]),_c('p',{staticClass:"text-weight-500"},[_vm._v(" "+_vm._s(_vm.countProjects)+"/"+_vm._s(_vm.MAX_COUNT)+" "+_vm._s(_vm.DESC)+" ")])])]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-70 md-size-50 md-small-size-90 md-xsmall-size-100 mx-auto"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationProvider',{staticClass:"login__label",attrs:{"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-large-input': true }
                      ]},[_c('label',[_vm._v(_vm._s(_vm.NAME_LABEL))]),_c('md-input',{attrs:{"type":"text","maxlength":_vm.MAX_NAME_LENGTH,"placeholder":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('span',{staticClass:"md-helper-error md-helper-text",class:{ show: failed }},[_vm._v(" "+_vm._s(_vm.NAME_ERROR)+" ")]),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v(" done ")])],1)],1)]}}],null,false,549366333)}),_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('md-field',{class:[
                        { 'md-error': failed },
                        { 'md-valid': passed }
                      ]},[_c('label',[_vm._v(" "+_vm._s(_vm.DESC_LABEL)+" ")]),_c('md-textarea',{staticClass:"md-textarea-large",attrs:{"maxlength":_vm.MAX_DESC_LENGTH,"md-autogrow":""},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}}),_c('span',{staticClass:"md-helper-error md-helper-text",class:{ show: failed }},[_vm._v(" "+_vm._s(_vm.DESC_ERROR)+" ")]),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v(" done ")])],1)],1)]}}],null,false,2432565703)})],1)]),_c('div',{staticClass:"login__actions md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-button',{staticClass:"md-block md-lg md-primary",attrs:{"type":"submit","disabled":_vm.pendingForm}},[_c('ButtonLoader',{attrs:{"text":_vm.BUTTON_SUBMIT,"is-load":_vm.pendingForm}})],1)],1)])])])],1)])]:[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-90 md-size-70 md-small-size-100 mx-auto text-center"},[_c('div',{staticClass:"h2"},[_vm._v(" "+_vm._s(_vm.LIMIT)+" "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.countProjects)+" of "+_vm._s(_vm.MAX_COUNT)+" ")])]),_c('md-button',{staticClass:"md-lg md-primary mt-5",attrs:{"to":{ name: 'Projects' }}},[_vm._v(" "+_vm._s(_vm.BACK)+" ")])],1)])]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }