import { DateTime } from "luxon";

export const formatDate = (str, type) => {
  if (!str) {
    return "";
  }

  let date;

  switch (type) {
    case "HTTP":
      date = DateTime.fromHTTP(str);
      break;
    case "ISO":
    default:
      date = DateTime.fromISO(str);
      break;
  }

  if (date.invalid) {
    return str;
  }
  return date.setLocale("en-US").toLocaleString(DateTime.DATETIME_MED);
};
