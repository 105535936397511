<template>
  <md-card class="articles__item" :class="{ disabled: !isActive }">
    <md-card-content>
      <div v-if="queries.length" class="articles__type hyphenate">
        <div v-for="queryId in normaliseQueryIds" :key="queryId" class="mr-5">
          {{ queryId }}
        </div>
      </div>
      <div class="articles__wr">
        <div class="articles__img">
          <img :src="thumbnail" :alt="item.source_name" />
        </div>

        <div class="articles__content">
          <div class="articles__top">
            <div class="articles__date hyphenate">{{ item.published_at }}</div>
            <div class="articles__company hyphenate">
              <img
                v-show="false"
                class="articles__company_img"
                src="@/assets/img/bg9.jpg"
                :alt="item.source_name"
              />
              <span>{{ item.source_name }}</span>
            </div>
            <div class="articles__name hyphenate">{{ item.author }}</div>
          </div>
          <a
            :href="item.url"
            target="_blank"
            rel="noopener noreferrer"
            class="articles__title hyphenate text-black"
          >
            {{ item.title }}
          </a>
          <div class="articles__desc hyphenate" v-html="item.desc"></div>
        </div>
        <div v-if="showActions" class="articles__actions">
          <md-button
            class="md-just-icon md-round"
            :class="{ 'md-secondary': isActive }"
            :disabled="pendingStatus"
            :title="
              isActive ? 'Exclude from analytics' : 'Include into analytics'
            "
            @click="
              isActive ? setDisabledInteresting() : setActiveInteresting()
            "
          >
            <ButtonLoader
              :is-load="pendingStatus"
              :accent="isActive"
              :icon="isActive ? 'close' : 'settings_backup_restore'"
            />
          </md-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import ButtonLoader from "@/components/Common/Button-loader";
import errorHandler from "@/utils/errorHandler";
export default {
  name: "ArticlesItem",
  components: { ButtonLoader },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    queries: {
      type: Array,
      default() {
        return [];
      }
    },
    showActions: {
      type: Boolean,
      default: false
    },
    isProject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pendingStatus: false,
      status: this.item.interesting
    };
  },
  computed: {
    isActive() {
      return this.status !== "no";
    },
    thumbnail() {
      return (
        this.item?.thumbnail || require("@/assets/img/article-placeholder.svg")
      );
    },
    queryIds() {
      return this.item.news_query_ids ?? [];
    },
    normaliseQueryIds() {
      return this.queryIds.map(query => {
        return query;
        // return this.queries.find(item => item.query_id === query).query_term;
      });
    }
  },
  methods: {
    setActiveInteresting() {
      this.sendInteresting("yes");
    },
    setDisabledInteresting() {
      this.sendInteresting("no");
    },
    sendInteresting(state) {
      this.pendingStatus = true;
      const params = {
        projectId: this.$route.params.id,
        queryId: this.$route.params.query,
        newsId: this.item.user_news_item_id,
        interesting: state
      };

      const TYPE_DISPATCH = this.isProject
        ? "SET_NEWS_INTERESTING_PROJECT"
        : "SET_NEWS_INTERESTING_QUERY";

      this.$store
        .dispatch(TYPE_DISPATCH, params)
        .then(() => {
          this.status = state;
        })
        .catch(err => this.$notifyError(errorHandler(err)))
        .finally(() => {
          this.pendingStatus = false;
        });
    }
  }
};
</script>
