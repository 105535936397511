<template>
  <div class="main">
    <div class="login">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <h2 class="login__title text-weight-500">
            Welcome to Signal<span class="text-primary">F</span>orge
          </h2>
        </div>
      </div>

      <div class="md-layout">
        <div class="login__container md-layout-item mx-auto">
          <div class="md-layout-item">
            <h4 class="mb-0 mt-0 text-weight-400">{{ DESC }}</h4>
          </div>

          <ValidationObserver ref="form">
            <form class="login__form" @submit.prevent="submit">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100">
                  <ValidationProvider
                    v-slot="{ passed, failed }"
                    mode="eager"
                    class="login__label"
                    rules="required|email"
                  >
                    <md-field
                      :class="[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-form-group': true }
                      ]"
                    >
                      <md-icon>email</md-icon>
                      <md-input
                        v-model="email"
                        type="email"
                        placeholder="Enter your email address to enable access"
                      >
                      </md-input>
                      <span
                        class="md-helper-error md-helper-text"
                        :class="{ show: failed }"
                      >
                        {{ EMAIL_ERROR }}
                      </span>
                      <slide-y-down-transition>
                        <md-icon v-show="failed" class="error">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon v-show="passed" class="success">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ passed, failed }"
                    mode="eager"
                    rules="required"
                  >
                    <md-field
                      :class="[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-form-group': true }
                      ]"
                    >
                      <md-icon>vpn_key</md-icon>
                      <md-input
                        v-model="accessCode"
                        type="text"
                        name="access"
                        placeholder="Enter access code"
                      >
                      </md-input>
                      <span
                        class="md-helper-error md-helper-text"
                        :class="{ show: failed }"
                      >
                        {{ PASSWORD_ERROR }}
                      </span>

                      <slide-y-down-transition>
                        <md-icon v-show="failed" class="error">
                          close
                        </md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon v-show="passed" class="success">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="login__actions md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-button
                    type="submit"
                    class="md-block md-lg md-primary"
                    :disabled="disabledBtn"
                  >
                    <ButtonLoader :text="SUBMIT" :is-load="pendingLogin" />
                  </md-button>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-button
                    type="button"
                    class="md-block md-lg md-outline"
                    :disabled="disabledBtn"
                    @click="openResendModal"
                  >
                    <span>
                      {{ RESEND_CODE }}
                    </span>
                  </md-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <p class="text-center">
                {{ BOTTOM_START }}
                <a
                  :href="`mailto:${BOTTOM_EMAIL}`"
                  target="blank"
                  class="text-primary text-weight-400"
                >
                  {{ BOTTOM_EMAIL }}
                </a>
                {{ BOTTOM_END }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-if="showResendModal" @close="modalResendHide">
      <template slot="header">
        <h4 class="modal-title text-weight-400">{{ MODAL_TITLE }}</h4>
      </template>
      <template slot="body">
        <p class="pre-line">{{ MODAL_DESC }}</p>

        <ValidationObserver ref="formResend">
          <form @submit.prevent="submitResend">
            <ValidationProvider
              v-slot="{ passed, failed }"
              mode="eager"
              rules="required|email"
            >
              <md-field
                :class="[
                  { 'md-error': failed },
                  { 'md-valid': passed },
                  { 'md-form-group': true }
                ]"
              >
                <md-icon>email</md-icon>
                <md-input
                  v-model="email"
                  type="email"
                  placeholder="Enter your email address to enable access"
                >
                </md-input>
                <span
                  class="md-helper-error md-helper-text"
                  :class="{ show: failed }"
                >
                  {{ MODAL_EMAIL_ERROR }}
                </span>

                <slide-y-down-transition>
                  <md-icon v-show="failed" class="error">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon v-show="passed" class="success">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <div class="mx-auto text-center">
              <md-button
                type="submit"
                class="md-lg md-primary"
                :disabled="disabledBtn"
              >
                <ButtonLoader :text="MODAL_SUBMIT" :is-load="pendingRefresh" />
              </md-button>
            </div>
          </form>
        </ValidationObserver>
      </template>
      <template slot="footer"> </template>
    </modal>
  </div>
</template>

<script>
import ButtonLoader from "@/components/Common/Button-loader";
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, email, length } from "vee-validate/dist/rules";
import { Modal } from "@/components";
import md5 from "md5";
import errorHandler from "@/utils/errorHandler";
import { LOGIN } from "@/utils/constants";

extend("required", required);
extend("email", email);
extend("length", length);

export default {
  name: "Home",
  components: {
    ButtonLoader,
    SlideYDownTransition,
    Modal
  },
  data() {
    return {
      email: "",
      accessCode: "",
      pendingLogin: false,
      pendingRefresh: false,
      showResendModal: false
    };
  },
  created() {
    this.TITLE = LOGIN.TITLE;
    this.DESC = LOGIN.DESC;

    this.EMAIL_ERROR = LOGIN.FORM.EMAIL_ERROR;
    this.PASSWORD_ERROR = LOGIN.FORM.PASSWORD_ERROR;
    this.RESEND_CODE = LOGIN.FORM.RESEND_CODE;
    this.SUBMIT = LOGIN.FORM.SUBMIT;

    this.BOTTOM_EMAIL = LOGIN.BOTTOM.EMAIL;
    this.BOTTOM_START = LOGIN.BOTTOM.START;
    this.BOTTOM_END = LOGIN.BOTTOM.END;

    this.MODAL_TITLE = LOGIN.MODAL.TITLE;
    this.MODAL_DESC = LOGIN.MODAL.DESC;
    this.MODAL_EMAIL_ERROR = LOGIN.MODAL.EMAIL_ERROR;
    this.MODAL_SUBMIT = LOGIN.MODAL.SUBMIT;
  },
  mounted() {
    if (this.$store.getters["IS_LOGGED_IN"]) {
      this.$router.push({ name: "Projects" });
    }
  },
  computed: {
    token() {
      return this.$store.getters["GET_TOKEN"];
    },
    disabledBtn() {
      return this.pendingRefresh || this.pendingLogin;
    }
  },
  methods: {
    modalResendHide() {
      this.showResendModal = false;
    },
    openResendModal() {
      this.showResendModal = true;
    },
    submitResend() {
      return this.$refs.formResend.validate().then(res => {
        if (res) {
          this.pendingRefresh = true;
          this.$store
            .dispatch("FETCH_CODE", { email: this.email.trim() })
            .then(() => {
              this.showResendModal = false;
              this.$notifySuccess(LOGIN.SUCCESS_LOGIN);
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingRefresh = false;
            });
        }
      });
    },
    submit() {
      this.$refs.form.validate().then(res => {
        if (res && this.accessCode) {
          this.pendingLogin = true;
          this.$store
            .dispatch("LOGIN", {
              email: this.email.trim(),
              enct_act_code: md5(this.accessCode)
            })
            .then(() => {
              this.$router.push({ name: "Projects" });
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingLogin = false;
            });
        } else {
          this.$refs.form.validate();
        }
      });
    }
  }
};
</script>
