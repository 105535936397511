<template>
  <div class="main">
    <div class="md-layout">
      <div class="md-layout-item">
        <Breadcrumbs />
      </div>
    </div>

    <Preloader v-if="pendingProjects" :text="LOADING" />

    <div v-if="!pendingProjects" class="md-layout projects__list">
      <div
        class="mb-5 md-layout-item md-medium-size-50 md-size-25 md-xsmall-size-100"
      >
        <div class="projects__item projects__item-new">
          <router-link
            :to="{ name: 'ProjectNew' }"
            class="md-hidden-link"
          ></router-link>
          <md-button
            class="md-just-icon md-outline md-primary md-round projects__add"
          >
            <md-icon>add_circle</md-icon>
          </md-button>
          <p>{{ NEW_PROJECT_BUTTON }}</p>
          <p></p>
        </div>
      </div>
      <ProjectsItem v-for="project in list" :key="project.id" :item="project" />
    </div>
  </div>
</template>

<script>
import ProjectsItem from "@/components/Projects/Projects-item";
import Preloader from "@/components/Common/Preloader";
import errorHandler from "@/utils/errorHandler";
import { PROJECTS } from "@/utils/constants";

export default {
  name: "Projects",
  components: {
    Preloader,
    ProjectsItem
  },
  data() {
    return {
      pendingProjects: true
    };
  },
  created() {
    this.LOADING = PROJECTS.LOADING;
    this.NEW_PROJECT_BUTTON = PROJECTS.NEW_PROJECT_BUTTON;

    this.$store
      .dispatch("FETCH_PROJECTS")
      .catch(err => this.$notifyError(errorHandler(err)))
      .finally(() => {
        this.pendingProjects = false;
        if (this.countProjects < 1) {
          this.$router.push({ name: "ProjectNew" });
        }
      });
  },
  computed: {
    list() {
      return this.$store.getters["GET_LIST_PROJECTS"];
    },
    countProjects() {
      return this.list.length;
    }
  }
};
</script>
