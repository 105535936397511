<template>
  <div class="md-layout mt-4">
    <div class="md-layout-item md-size-100">
      <div class="articles">
        <Preloader v-if="!newsInfo && pendingNews" :text="LOADING" />
        <div class="d-flex justify-content-between">
          <div ref="newsTitle" class="h4 mt-0 text-weight-400">
            {{ TITLE }}
          </div>
          <Pagination
            v-if="showNews"
            v-model="currentPage"
            class="mb-0 mt-0"
            :is-last="isLastPage"
            :disabled="!newsInfo && pendingNews"
          />
        </div>
        <template v-if="showNews">
          <div class="articles__list">
            <Article
              v-for="item in newsInfo.items"
              :key="item.user_news_item_id"
              :queries="newsInfo.news_queries"
              :item="item"
              :show-actions="showActions"
              :is-project="isProject"
            />
          </div>
          <Pagination
            v-model="currentPage"
            :is-last="isLastPage"
            :disabled="!newsInfo && pendingNews"
          />
        </template>
        <template v-if="!pendingNews && showNews === 0 && !isFirstLoad">
          <div class="h5 mt-4">{{ NOT_FOUND }}</div>
          <md-button
            v-if="currentPage !== 1"
            class="md-success"
            @click="currentPage = 1"
          >
            <ButtonLoader
              :text="REFRESH_BUTTON"
              :is-load="false"
              icon="refresh"
              icon-outline
            />
          </md-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Article from "@/components/Articles/Articles-item";
import ButtonLoader from "@/components/Common/Button-loader";
import Preloader from "@/components/Common/Preloader";
import { NEWS } from "@/utils/constants";

export default {
  name: "ArticlesList",
  components: {
    Preloader,
    ButtonLoader,
    Pagination,
    Article
  },
  props: {
    showActions: {
      type: Boolean,
      default: false
    },
    isProject: {
      type: Boolean,
      default: false
    }
  },
  destroyed() {
    if (this.isFirstLoad) {
      this.$store.commit("DELETE_NEWS");
    }
  },
  created() {
    this.$store.commit("SET_NEWS_PAGE", 1);
    this.$store.commit("SET_FIRST_STATUS", true);

    this.TITLE = NEWS.TITLE;
    this.NOT_FOUND = NEWS.NOT_FOUND;
    this.LOADING = NEWS.LOADING;
    this.REFRESH_BUTTON = NEWS.REFRESH_BUTTON;
  },
  computed: {
    isFirstLoad() {
      return this.$store.getters["IS_FIRST_LOADED"];
    },
    pendingNews() {
      return this.$store.getters["GET_NEWS_PENDING"];
    },
    newsInfo() {
      return this.$store.getters["GET_NEWS"];
    },
    currentPage: {
      get() {
        return this.$store.getters["GET_NEWS_PAGE"];
      },
      set(val) {
        this.$store.commit("SET_NEWS_PAGE", val);
      }
    },
    showNews() {
      return this.newsInfo?.items.length;
    },
    totalItems() {
      return Math.min(100, this.newsInfo.total_items || 100);
    },
    isLastPage() {
      return this.newsInfo?.is_last_page ?? true;
    }
  },
  watch: {
    currentPage() {
      this.scrollToTitle();
    }
  },
  methods: {
    scrollToTitle() {
      this.$refs.newsTitle.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }
};
</script>
