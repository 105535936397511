export const BASE_URL =
  "https://reguser-ui-service-dot-newsymon-s4d.ue.r.appspot.com/api";

export const PROJECTS = {
  MAX_COUNT: 50,
  LOADING: "Loading projects...",
  NEW_PROJECT_BUTTON: "New Project"
};

export const PROJECT = {
  MAX_NAME_LENGTH: 64,
  MAX_DESC_LENGTH: 200,
  NOT_LOADED: "Server error, try later",
  LOADING_PROJECT: "Loading project...",
  CREATE_PROJECT: {
    TITLE: "Get Started by Creating a Project",
    DESC: "projects created",
    LIMIT: "You have the maximum number of projects",
    BACK: "To projects page",
    NAME_LABEL: "Project Name*",
    NAME_ERROR: "Project Name is a required field.",
    DESC_LABEL: "Project Description*",
    DESC_ERROR: "Project Description is a required field.",
    BUTTON_SUBMIT: "SUBMIT"
  }
};

export const PROJECT_DETAILS = {
  TITLE: "Project Details",
  NAME: "Name",
  DESC: "Description",
  OWNER: "Owner",
  LAST_UPDATE: "Last Updated",
  TOTAL_ARTICLES: "Total Articles",
  SUCCESS_SAVE: "Project Details is save",
  FORM: {
    NAME_LABEL: "Name *",
    NAME_ERROR: "Project Name is a required field.",
    DESC_LABEL: "Description *",
    DESC_ERROR: "Project Description is a required field."
  }
};

export const PROJECT_ANALYSIS = {
  TITLE: "Analysis Details",
  DASHBOARD: "open in dashboard",
  SCHEDULE: "Schedule",
  MAX_RES_QUERY: "Max Results per Query",
  LAST_ANALYSIS: "Last Analysis",
  STATUS: "Status",
  SUCCESS_SAVE: "Analysis Details is save",
  FORM: {
    SCHEDULE_LABEL: "Schedule",
    MAX_RES_QUERY_LABEL: "Max Results per Query",
    MAX_RES_QUERY_MSG:
      "Enter a whole number or leave empty for unlimited results",
    MAX_RES_QUERY_ERROR: "Project Description is a required field."
  }
};

export const QUERY = {
  NEW_QUERY_TITLE: "Create New Query",
  TERM: "Term",
  NEWS_GROUP: "News Group",
  FACET: "Facet",
  SITE: "Site",
  SCOPE: "Scope",
  ADDED: "Added",
  ARTICLES: "Articles",
  SUCCESS_SAVE: "Query saved successfully",
  SUCCESS_UPDATE: "Query updated successfully",
  FORM: {
    QUERY_NAME_LABEL: "Query Name*",
    QUERY_NAME_ERROR: "Please enter a query name",
    SEARCH_TYPE_LABEL: "Search Type*",
    SEARCH_TYPE_ERROR: "Please enter a valid Search Type",
    QUERY_TERM_LABEL: "Query Term*",
    QUERY_TERM_ERROR: "Please enter a query term",
    FEED_LABEL: "Feed",
    SITE_LABEL: "Site",
    SEARCH_SCOPE_LABEL: "Search Scope",
    SEARCH_BUTTON: "check search results",
    SAVE_BUTTON: "save query"
  },
  DELETE: {
    ALERT_TITLE: "Are you sure you want to delete query",
    ALERT_DESC: "This action cannot be undone",
    SUCCESS: "Deleted Query:"
  }
};

export const NEWS = {
  TITLE: "Results",
  NOT_FOUND: "News not found",
  LOADING: "Loading news..",
  REFRESH_BUTTON: "go to first page"
};

export const SIDEBAR = {
  PROJECT_NAME_DEFAULT: "Project",
  PROJECT_QUERIES: "PROJECT QUERIES",
  CREATE_QUERY: "Create New Query"
};

export const LOGIN = {
  TITLE: "Welcome to SignalForge",
  DESC: "Sign In",
  SUCCESS_LOGIN:
    "An email with access code was sent to your email address, if it exists in our system.",
  FORM: {
    EMAIL_ERROR: "Please enter a valid email",
    PASSWORD_ERROR:
      "Something went wrong. Try again or click Resend Access Code.",
    RESEND_CODE: "resend access code",
    SUBMIT: "SUBMIT"
  },
  BOTTOM: {
    EMAIL: "support@science4data.com",
    START: "Contact",
    END: "with questions"
  },
  MODAL: {
    TITLE: "Resend Access Code",
    DESC:
      "Enter the email address you used to access SignalForge.\n We will resend your access code.",
    EMAIL_ERROR: "Please enter a valid email",
    SUBMIT: "SEND"
  }
};
