<template>
  <div class="sidebar">
    <div class="logo">
      <a
        href="https://science4data.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="logo-mini simple-text"
      >
        <div class="logo-img">
          <img src="@/assets/img/logo-i-small.svg" alt="logo" />
        </div>
      </a>
      <a
        href="https://science4data.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="logo-normal simple-text"
      >
        <img src="@/assets/img/logo-i.svg" alt="logo" />
      </a>
      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-just-icon md-round md-transparent"
          @click="minimizeSidebar"
        >
          <i
            class="material-icons text_align-center visible-on-sidebar-regular"
          >
            more_vert
          </i>
          <i
            class="design_bullet-list-67 material-icons visible-on-sidebar-mini"
          >
            view_list
          </i>
        </md-button>
      </div>
    </div>
    <div ref="sidebarScrollArea" class="sidebar-wrapper">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, i) in link.children"
              :key="subLink.name + i"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  props: {
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  }
};
</script>

<style>
@media (min-width: 992px) {
  /*.navbar-search-form-mobile,*/
  /*.nav-mobile-menu {*/
  /*  display: none;*/
  /*}*/
}
</style>
