import VueRouter from "vue-router";
import store from "@/store";
import routes from "@/routes/routes";

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.IS_LOGGED_IN) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
