var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('Header',{attrs:{"has-sidebar":true}}),_c('side-bar',{attrs:{"data-background-color":"black"}},[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.projectInfo ? _vm.projectInfo.name : _vm.PROJECT_NAME_DEFAULT)),
          icon: 'dashboard',
          iconOutline: true,
          path: ("/project/" + (_vm.$route.params.id))
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.CREATE_QUERY,
          icon: 'add_circle_outline',
          path: ("/project/" + (_vm.$route.params.id) + "/query-new")
        }}}),_c('hr'),_c('h5',{staticClass:"sidebar__subtitle"},[_vm._v(_vm._s(_vm.PROJECT_QUERIES))]),_vm._l((_vm.queryList),function(link,i){return _c('sidebar-item',{key:link.project_query_id,attrs:{"link":{
          name: ("" + (link.query_name ? link.query_name : ("Query " + (i + 1)))),
          desc: ("" + (link.query_term ? link.query_term : '')),
          source_id: link.input_source_id,
          path: ("/project/" + (_vm.$route.params.id) + "/query/" + (link.project_query_id))
        },"tooltip":""}})})],2)],2),_c('div',{staticClass:"main-panel"},[_c('div',{staticClass:"content"},[_c('router-view')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }