<template>
  <div class="md-layout">
    <div class="breadcrumb-wrapper md-layout-item">
      <Breadcrumbs v-if="show" />
    </div>
  </div>
</template>

<script>
import { anyToString, sliceText } from "@/utils/string";
import { PROJECT } from "@/utils/constants";

export default {
  name: "ProjectBreadcrumbs",
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    info() {
      return this.$store.getters["GET_PROJECT"];
    },
    infoQuery() {
      return this.$store.getters["GET_QUERY_LIST"];
    },
    projectName() {
      return anyToString(this.info?.name);
    },
    query() {
      return this.$route.params.query;
    }
  },
  watch: {
    projectName() {
      this.refresh();
    },
    infoQuery() {
      this.refresh();
    },
    query() {
      this.refresh();
    },
    info() {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      this.show = false;
      if (this.projectName.length) {
        this.$route.params.name = this.cropText(this.projectName);

        if (this.infoQuery) {
          const queryItem = this.infoQuery.find(
            item => item.project_query_id === +this.$route.params.query
          );
          if (queryItem) {
            this.$route.params.queryName = this.cropText(queryItem.query_name);
          }
        }
      }
      setTimeout(() => {
        this.show = true;
      }, 0);
    },
    cropText(str) {
      return sliceText(str, PROJECT.MAX_NAME_LENGTH, true);
    }
  }
};
</script>
