<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div class="h2 text-center">{{ title }}</div>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-70 md-size-60 md-small-size-90 md-xsmall-size-100 mx-auto p-0"
      >
        <ValidationObserver ref="form">
          <form class="" @submit.prevent="saveQuery">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <ValidationProvider
                  v-slot="{ passed, failed }"
                  mode="eager"
                  class="d-flex mb-4"
                  rules="required"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <label>{{ QUERY_NAME_LABEL }}</label>
                    <md-input
                      v-model="name"
                      type="text"
                      placeholder=""
                    ></md-input>
                    <span
                      class="md-helper-error md-helper-text"
                      :class="{ show: failed }"
                    >
                      {{ QUERY_NAME_ERROR }}
                    </span>
                    <slide-y-down-transition>
                      <md-icon v-show="failed" class="error">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon v-show="passed" class="success">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-size-50 md-small-size-100">
                <ValidationProvider
                  v-slot="{ passed, failed }"
                  mode="eager"
                  class="d-flex mb-4"
                  rules="required"
                >
                  <md-field
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  >
                    <ButtonLoader
                      v-if="!newsType.length"
                      is-load
                      accent
                      center
                    />
                    <label>{{ SEARCH_TYPE_LABEL }}</label>
                    <md-select v-model="type" :disabled="!newsType.length">
                      <md-optgroup
                        v-for="(group, key) in typeOptions"
                        :key="key"
                        :label="key"
                      >
                        <md-option
                          v-for="item in group"
                          :key="item.engine_id"
                          :value="item.engine_id"
                        >
                          {{ item.name }}
                        </md-option>
                      </md-optgroup>
                    </md-select>
                    <span
                      class="md-helper-error md-helper-text"
                      :class="{ show: failed }"
                    >
                      {{ SEARCH_TYPE_ERROR }}
                    </span>
                    <slide-y-down-transition>
                      <md-icon v-show="failed" class="error">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon v-show="passed" class="success">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div v-if="!showFeeds" class="md-layout-item md-size-100">
                <ValidationProvider
                  v-slot="{ passed, failed }"
                  mode="eager"
                  class="d-flex mb-4"
                  :rules="!showFeeds ? 'required' : ''"
                >
                  <md-autocomplete
                    v-model="termQuery"
                    :md-options="termOptions"
                    :class="{ 'md-error': failed, 'md-valid': passed }"
                  >
                    <label>{{ QUERY_TERM_LABEL }}</label>
                    <template
                      slot="md-autocomplete-item"
                      slot-scope="{ item, term }"
                    >
                      <md-highlight-text :md-term="term">
                        {{ item }}
                      </md-highlight-text>
                    </template>

                    <span
                      class="md-helper-error md-helper-text"
                      :class="{ show: failed }"
                    >
                      {{ QUERY_TERM_ERROR }}
                    </span>
                    <slide-y-down-transition>
                      <md-icon v-show="failed" class="error">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon v-show="passed" class="success">done</md-icon>
                    </slide-y-down-transition>
                  </md-autocomplete>
                </ValidationProvider>
              </div>

              <div v-if="showFeeds" class="md-layout-item md-size-100">
                <ValidationProvider mode="eager" class="d-flex mb-4" rules="">
                  <md-field>
                    <label>{{ FEED_LABEL }}</label>
                    <md-select v-model="termQuery">
                      <md-option
                        v-for="item in feedOptions"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-size-50 md-small-size-100">
                <ValidationProvider mode="eager" class="d-flex mb-4" rules="">
                  <md-field>
                    <ButtonLoader
                      v-if="!newsType.length"
                      is-load
                      accent
                      center
                    />
                    <label>{{ SITE_LABEL }}</label>
                    <md-select v-model="site" :disabled="!siteOptions.length">
                      <md-option value="">All</md-option>
                      <md-option
                        v-for="item in siteOptions"
                        :key="item.url"
                        :value="item.url"
                      >
                        {{ item.org }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-size-50 md-small-size-100">
                <ValidationProvider mode="eager" class="d-flex mb-4" rules="">
                  <md-field>
                    <label>{{ SEARCH_SCOPE_LABEL }}</label>
                    <md-select v-model="scope">
                      <md-option
                        v-for="item in scopeOptions"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-button
                  type="button"
                  class="md-block md-lg md-outline"
                  :disabled="disabledBtn"
                  @click="getSearch"
                >
                  <ButtonLoader
                    :text="SEARCH_BUTTON"
                    :is-load="pendingResults"
                    accent
                  />
                </md-button>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-button
                  type="submit"
                  class="md-block md-lg md-primary"
                  :disabled="disabledBtn || !queryId || isFirstLoad"
                >
                  <ButtonLoader :text="SAVE_BUTTON" :is-load="pendingSave" />
                </md-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLoader from "@/components/Common/Button-loader";
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, length } from "vee-validate/dist/rules";
import errorHandler from "@/utils/errorHandler";
import { QUERY } from "@/utils/constants";

extend("required", required);
extend("length", length);

export default {
  name: "Form",
  components: {
    ButtonLoader,
    SlideYDownTransition
  },
  props: {
    title: {
      type: String,
      default: "Create New Query"
    },
    edit: {
      type: Boolean
    }
  },
  data() {
    return {
      queryId: undefined,
      pendingSave: false,
      pendingResults: false,
      loadedResults: false,
      termOptions: [],
      scopeOptions: [
        {
          value: "",
          label: "Anywhere in the page"
        },
        {
          value: "allintitle",
          label: "In the title of page"
        },
        {
          value: "allintext",
          label: "In the text of page"
        }
      ],
      feedOptions: [
        {
          value: "",
          label: "USA Today Headline News"
        },
        {
          value: "222",
          label: "USA Today Headline News 2"
        },
        {
          value: "333",
          label: "USA Today Headline News 3"
        }
      ]
    };
  },
  created() {
    this.QUERY_NAME_LABEL = QUERY.FORM.QUERY_NAME_LABEL;
    this.QUERY_NAME_ERROR = QUERY.FORM.QUERY_NAME_ERROR;
    this.SEARCH_TYPE_LABEL = QUERY.FORM.SEARCH_TYPE_LABEL;
    this.SEARCH_TYPE_ERROR = QUERY.FORM.SEARCH_TYPE_ERROR;
    this.QUERY_TERM_LABEL = QUERY.FORM.QUERY_TERM_LABEL;
    this.QUERY_TERM_ERROR = QUERY.FORM.QUERY_TERM_ERROR;
    this.FEED_LABEL = QUERY.FORM.FEED_LABEL;
    this.SITE_LABEL = QUERY.FORM.SITE_LABEL;
    this.SEARCH_SCOPE_LABEL = QUERY.FORM.SEARCH_SCOPE_LABEL;
    this.SEARCH_BUTTON = QUERY.FORM.SEARCH_BUTTON;
    this.SAVE_BUTTON = QUERY.FORM.SAVE_BUTTON;
  },
  mounted() {
    this.$store.commit("DELETE_NEWS");
  },
  beforeDestroy() {
    this.$store.commit("DELETE_NEWS");
    this.$store.commit("RESET_QUERY_FORM");
  },
  computed: {
    showFeeds() {
      return this.type === "feeds";
    },
    queryForm() {
      return this.$store.getters["GET_QUERY_FORM"];
    },
    name: {
      get() {
        return this.queryForm.name;
      },
      set(val) {
        this.queryForm.name = val;
      }
    },
    type: {
      get() {
        return this.queryForm.type;
      },
      set(val) {
        this.queryForm.type = val;
      }
    },
    termQuery: {
      get() {
        return this.queryForm.termQuery;
      },
      set(val) {
        this.queryForm.termQuery = val;
      }
    },
    site: {
      get() {
        return this.queryForm.site ?? "";
      },
      set(val) {
        this.queryForm.site = val;
      }
    },
    scope: {
      get() {
        return this.queryForm.scope ?? "";
      },
      set(val) {
        this.queryForm.scope = val;
      }
    },
    isFirstLoad() {
      return this.$store.getters["IS_FIRST_LOADED"];
    },
    disabledBtn() {
      return this.pendingSave || this.pendingResults || !this.newsType.length;
    },
    newsType() {
      return this.$store.getters["GET_NEWS_TYPE"];
    },
    typeOptions() {
      return this.$store.getters["GET_NEWS_TYPE_FORMAT"];
    },
    siteOptions() {
      return (
        this.newsType?.find(item => item.engine_id === this.type)?.sites || []
      );
    },
    newsInfo() {
      return this.$store.getters["GET_NEWS"];
    },
    newsQueryId() {
      return this.newsInfo ? this.newsInfo.query_id : undefined;
    },
    currentPage() {
      return this.$store.getters["GET_NEWS_PAGE"];
    }
  },
  watch: {
    termQuery() {
      this.queryId = undefined;
      this.getTermHint();
    },
    site() {
      this.queryId = undefined;
    },
    type(newType, oldType) {
      if (newType === "feeds" || oldType === "feeds") {
        this.termQuery = "";
      }
      this.queryId = undefined;
      this.site = "";
    },
    newsQueryId(val) {
      this.queryId = val;
    },
    currentPage() {
      this.getSearch();
    }
  },
  methods: {
    getTermHint() {
      if (this.termQuery && this.termQuery.trim().length) {
        this.$http
          .post("news/query-term-hints", {
            query_term: this.termQuery,
            input_source_id: this.type
          })
          .then(res => {
            this.termOptions = res.data.items.map(str =>
              str.replace(/(<([^>]+)>)/gi, "").trim()
            );
          });
      }
    },
    getSearch() {
      this.$store.commit("DELETE_NEWS");
      this.$refs.form.validate().then(res => {
        if (res) {
          this.pendingResults = true;
          this.$store
            .dispatch("FETCH_NEWS", {
              query_term: this.termQuery,
              input_source_id: this.type,
              page_index: this.currentPage - 1,
              search_scope: this.scope,
              site: this.site
            })
            .then(() => {
              this.loadedResults = true;
            })
            .catch(err => this.$notifyError(errorHandler(err)))
            .finally(() => {
              this.pendingResults = false;
            });
        }
      });
    },
    saveQuery() {
      this.$refs.form.validate().then(res => {
        if (res) {
          if (this.queryId) {
            this.pendingSave = true;
            let newQueryNId = this.$route.params.query;

            new Promise((resolve, reject) => {
              if (this.edit) {
                return this.$store
                  .dispatch("UPDATE_QUERY", {
                    projectId: this.$route.params.id,
                    queryID: this.$route.params.query,
                    params: {
                      query_name: this.name,
                      query_term: this.termQuery,
                      input_source_id: this.type,
                      search_scope: this.scope,
                      site: this.site
                    }
                  })
                  .then(res => {
                    this.$notifySuccess(QUERY.SUCCESS_UPDATE);
                    resolve(res);
                  })
                  .catch(reject);
              } else {
                return this.$store
                  .dispatch("CREATE_QUERY", {
                    id: this.$route.params.id,
                    params: {
                      query_id: this.queryId,
                      query_name: this.name
                    }
                  })
                  .then(res => {
                    newQueryNId = res.data.project_query_id;
                    this.$notifySuccess(QUERY.SUCCESS_SAVE);
                    resolve(res);
                  })
                  .catch(reject);
              }
            })
              .then(() => {
                return this.$store.dispatch(
                  "FETCH_QUERY_LIST",
                  this.$route.params.id
                );
              })
              .finally(() => {
                this.pendingSave = false;
              })
              .then(() => {
                this.$router.push({
                  path: `/project/${this.$route.params.id}/query/${newQueryNId}`
                });
              })
              .catch(err => this.$notifyError(errorHandler(err)));
          }
        }
      });
    }
  }
};
</script>
